.btn{
  font-size:rem(30px);
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  min-width: 240px;
  line-height: 62px;
  font-weight: 300;
  display: inline-block;
  transition: all .4s linear;
  border-radius: 10px;
  background: rgba(233,44,94,1);
  background: -moz-linear-gradient(left, rgba(233,44,94,1) 0%, rgba(208,6,174,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(233,44,94,1)), color-stop(100%, rgba(208,6,174,1)));
  background: -webkit-linear-gradient(left, rgba(233,44,94,1) 0%, rgba(208,6,174,1) 100%);
  background: -o-linear-gradient(left, rgba(233,44,94,1) 0%, rgba(208,6,174,1) 100%);
  background: -ms-linear-gradient(left, rgba(233,44,94,1) 0%, rgba(208,6,174,1) 100%);
  background: linear-gradient(to right, rgba(233,44,94,1) 0%, rgba(208,6,174,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e92c5e', endColorstr='#d006ae', GradientType=1 );
  box-shadow: 0 18px 59px 0 rgba(0, 0, 0, 0.4);
  &:hover{
    box-shadow: 0 18px 59px 0 rgba(0, 0, 0, 0.7);
  }
}
@media(max-width:767px){
  .btn{
    line-height: 50px;
    min-width: 200px;;
  }
}
@media(max-width:639px){
  .btn{
    min-width: 170px;;
  }
}
@media(max-width:479px){
  .btn {
    font-size: 16px;
    min-width: 130px;
  }
}