//
// Headings
//
h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    margin-bottom: 0;
    margin-top: 0;
    display: block;
  }
  
//   h1,.h1{
//     font-size:rem(32px);
//     text-transform: uppercase;
//     font-weight: 500;
//     color:#18689c;
//     line-height: 1.5;
//   }
  
  h2,.h2{
    font-size: rem(40px);
    color:#e92c5d;
    font-weight: 300;
    line-height: 1.4;
    text-transform: uppercase;
  }
  
  h3,.h3{
    font-size:rem(20px);
    font-family: 'next_art';
    font-weight: normal;
    text-transform: uppercase;
  }
  h4,.h4{
    font-size:rem(28px);
  }
  
  //
  // Text
  //
  
//   .text-wrap{
//     b{
//       color:$green;
//       font-weight: 900;
//     }
//     p{
//       & + p{
//         margin-top: 26px;
//       }
//     }
//   }
//   @media(max-width:1400px){
//     h2, .h2{
//       font-size: 2rem;
//     }
//   }
  
//   @media(max-width:959px){
//     h2, .h2{
//       font-size: 2rem;
//     }
//   }
  
//   @media(max-width:479px){
//     h1,.h1{
//       font-size: 24px;
//     }
//     h2, .h2{
//       font-size: 20px;
//     }
//   }
//   @media(max-width:375px){
//     h2, .h2{
//       font-size: 18px;
//     }
//   }