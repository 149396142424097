$browser-size: 18px; // Default

@media(max-width:1199px){
  $browser-size: 14px; 
}

@function rem($pixels, $context: $browser-size) {
  @return #{$pixels/$context}rem;
}


// Colors
$text-color: #000;
$red: #ed1651;

// Layout
$max-width: 100%;

// $easing: cubic-bezier(0.19, 1, 0.22, 1);
$easing: cubic-bezier(0.4, 0, 1, 1);