




@font-face {
    font-family: 'russo_oneregular';
    src: url('../fonts/Russo/9928-webfont.woff2') format('woff2'),
         url('../fonts/Russo/9928-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}