.main-footer{
    background: rgba(245,58,108,1);
    background: -moz-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,58,108,1)), color-stop(100%, rgba(208,6,174,1)));
    background: -webkit-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
    background: -o-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
    background: -ms-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
    background: linear-gradient(to right, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
    padding: 15px 0;
    font-size: rem(24px);
    color:#fff;
    .row{
        align-items: center;
        position: relative;
    }
    .copy{
        font-weight: 300;
    }
    .logo{
        margin-right: 55px;
        display: inline-block;
        &:after{
            content: "";
            width: 37px;
            height: 87px;
            background: url(../img/shadow-l.png) no-repeat;
            position: absolute;
            top: -6px;
            left: 195px;
        }
    }
}

@media(max-width:1260px){
    .main-footer .logo{
        &::after{
            display: none;
        }
        img{
            width: 140px;
        }
    }
}
@media(max-width:959px){
    .main-footer .logo img {
        width: 100px;
    }
}