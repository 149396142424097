.section{
    padding-top: rem(75px);
}
.global-title{
    padding: 27px 0;
    .text{
        font-size: rem(30px);
        font-weight: 300;
        text-align: center;
        max-width: 560px;
        margin: 30px auto 0;
    }
}
.title-small_box{
    color:#fff;
    text-align: center;
    max-width: 860px;
    margin: 0 auto;
    font-weight: 700;
    font-size: rem(32px);
    margin-bottom: rem(55px);
    display: block;
}
.fig-circle{
    width: 75px;
    height: 75px;
    position: absolute;
    bottom: -37px;
    left:0;
    right: 0;
    margin: auto;
    cursor: pointer;
    z-index: 2;
    span{
        border-radius: 50%;
        width: 75px;
        height: 75px;
        display: inline-block;
        border: 14px solid #fff;
        -webkit-box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.4);
        box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.4);
        background: rgba(177,177,177,1);
        background: -moz-linear-gradient(left, rgba(177,177,177,1) 0%, rgba(138,138,138,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(177,177,177,1)), color-stop(100%, rgba(138,138,138,1)));
        background: -webkit-linear-gradient(left, rgba(177,177,177,1) 0%, rgba(138,138,138,1) 100%);
        background: -o-linear-gradient(left, rgba(177,177,177,1) 0%, rgba(138,138,138,1) 100%);
        background: -ms-linear-gradient(left, rgba(177,177,177,1) 0%, rgba(138,138,138,1) 100%);
        background: linear-gradient(to right, rgba(177,177,177,1) 0%, rgba(138,138,138,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b1b1b1', endColorstr='#8a8a8a', GradientType=1 );
        &::before{
            content: "";
            width: 14px;
            height: 24px;
            position: absolute;
            left:0;
            right: 0;
            margin: auto;
            transition: all .4s linear;
            bottom: 0;
            top:0;
            margin: auto;
            background: url(../img/svg/arrow-r.svg) no-repeat;
        }
    }
}

.btn-more{
    font-size: rem(30px);
    text-align: center;
    background: #fff;
    border-radius: 10px;
    line-height: 62px;
    display: inline-block;
    min-width: 250px;
    box-shadow: 0 18px 59px 0 rgba(0, 0, 0, 0.4);
    padding: 0 18px 0 68px;
    font-weight: 300;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    transition: all .4s linear;
    i{
        border-radius: 50%;
        left:10px;
        top:0;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 50px;
        height: 50px;
        background: #ad399c;
        background: -webkit-gradient(left top, right top, color-stop(0%, #ad399c), color-stop(100%, #eb1750));
        background: -webkit-linear-gradient(left, #ad399c 0%, #eb1750 100%);
        background: -o-linear-gradient(left, #ad399c 0%, #eb1750 100%);
        background: -webkit-gradient(linear, left top, right top, from(#ad399c), to(#eb1750));
        background: linear-gradient(to right, #ad399c 0%, #eb1750 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ad399c', endColorstr='#eb1750', GradientType=1 );
        &::before{
            content: "";
            width: 14px;
            height: 24px;
            position: absolute;
            left:0;
            right: 0;
            margin: auto;
            bottom: 0;
            top:0;
            margin: auto;
            background: url(../img/svg/arrow-r.svg) no-repeat;
        }
    }
    &:hover{
        box-shadow: 0 18px 59px 0 rgba(0, 0, 0, 0.7);
    }
}


@keyframes pulse{
    0%{transform:scale(1);}
    50%{transform:scale(1.1);}
    50%{transform:scale(0.9);}
    70%{transform:scale(1.1);}
    100%{transform:scale(1);}
}