.contact{
    &-section{
        margin-bottom: rem(100px);
        padding: 0 15px;
        &.animated{
            .container{
                transform: translateY(0px);
                opacity: 1;
            }
        }
        .container{
            padding: rem(75px) rem(55px) rem(75px) rem(70px);
            border-radius: 15px;
            box-shadow: 0 0 59px 0 rgba(#000,0.29);
            max-width: 1450px;
            transform: translateY(300px);
            transition: all .5s linear;
            opacity: 0;
            .row{
                align-items: center;
            }
        }
        .map{
            border-radius: 15px;
            overflow: hidden;
            position: relative;
            box-shadow: 0 9px 59px 0 rgba(#000,0.29);
            .marker{
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: $red;
                cursor: pointer;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left:0;
                right: 0;
                z-index: 2;
                animation: pulse-s 2s infinite;
                -webkit-box-shadow: 0 0 0 rgba($red, 0.5);
                box-shadow: 0 0 0 rgba($red, 0.5);
            }
            img{
                display: block; 
            }
        }
    }
    &-info{
        padding: 0 rem(70px);
        font-weight: 300;
        font-size: rem(24px);
        line-height: 28px;
        p,address{
            margin-bottom: 25px;
        }
        a{
            display: table;
            color:$text-color;
            &:hover{
                color:$red;
            }
        }
    }
    &-menu{
        padding-right: rem(100px);
        position: relative;
        font-size: rem(24px);
        font-weight: 300;
        &:after{
            content: '';
                width: 2px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                background: rgba(110, 110, 110, 0);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(110, 110, 110, 0)), color-stop(0%, rgba(110, 110, 110, 0)), color-stop(21%, #6e6e6e), color-stop(80%, #6e6e6e), color-stop(100%, rgba(110, 110, 110, 0)));
                background: -webkit-linear-gradient(top, rgba(110, 110, 110, 0) 0%, rgba(110, 110, 110, 0) 0%, #6e6e6e 21%, #6e6e6e 80%, rgba(110, 110, 110, 0) 100%);
                background: -o-linear-gradient(top, rgba(110, 110, 110, 0) 0%, rgba(110, 110, 110, 0) 0%, #6e6e6e 21%, #6e6e6e 80%, rgba(110, 110, 110, 0) 100%);
                background: -webkit-gradient(linear, left top, left bottom, from(rgba(110, 110, 110, 0)), color-stop(0%, rgba(110, 110, 110, 0)), color-stop(21%, #6e6e6e), color-stop(80%, #6e6e6e), to(rgba(110, 110, 110, 0)));
                background: linear-gradient(to bottom, rgba(110, 110, 110, 0) 0%, rgba(110, 110, 110, 0) 0%, #6e6e6e 21%, #6e6e6e 80%, rgba(110, 110, 110, 0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6e6e6e', endColorstr='#6e6e6e', GradientType=0 );
        }
        li{
            line-height: 50px;
        }
        a{
            color:$text-color;
            &:hover{
                color:$red;
            }
        }
    }
}

@media(max-width:1260px){
    .contact-info{
        padding: 0 20px;
    }
    .contact-menu{
        padding-right: 30px;
    }
    .contact-section .container{
        padding: 3.16667rem 2.5556rem;
    }
}
@media(max-width:1023px){
    .contact-section .map img{
        width:100%;
    }
}
@media(max-width:959px){
    .contact-section .row{
        flex-wrap: wrap;
    }
    .contact-section .map{
        flex:0 0 100%;
        margin-top: 25px;
    }
    .contact-menu{
        flex:0 0 30%;
    }
    .contact-info{
        padding: 0 40px;
    }
}
@media(max-width:639px){
    .contact-menu{
        display: none;
    }
    .contact-info{
        padding: 0;
    }
}
@media(max-width:479px){
    .contact-section .container{
        padding: 20px 15px;
    }
}

@keyframes pulse-s {
    0% {
        box-shadow: 0 0 0 0 rgba($red, 0.7);
    }
    
    70% {
        box-shadow: 0 0 0 18px rgba(204, 169, 44, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}