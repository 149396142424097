.global-banner{
    z-index: 2;
    .slick-list{
        overflow: visible;
        padding-bottom: 32px;
    }
    &__item{
        height: 700px;
        background-size: cover;
        display: flex;
        background-position: center;
        .container{
            align-items: flex-end;
            height: 100%;
            display: flex;
        }
        &:nth-child(1){
            .global-banner__box{
                background: url(../img/blur1.jpg) no-repeat;
            }
        }
    }
    .slick-slide.slick-current{
        .global-banner__box{
            transform: translateX(0);
            opacity: 1;
        }
    }
    &__box{
        border-radius: 10px;
        padding: 35px 35px 68px;
        position: relative;
        bottom: -20px;
        max-width: 580px;
        transform: translateX(-100px);
        opacity: 0;
        display: flex;
        transition: all .4s linear;
        transition-delay: 0.5s;
        justify-content: space-between;
        box-shadow: 0 8px 32px 0 rgba(#000,0.45);
        border:1px solid #ddd;
        flex-wrap: wrap;
        > *{
            position: relative;
            z-index: 2;
        }
        // .btn{
        //     margin-right: -60px;
        // }
        .title{
            line-height: 1.2;
            flex:0 0 100%;
            display: block;
            font-weight: 300;
            font-size: rem(40px);
            margin-bottom: 30px;
        }
    }
    .slick-slide.slick-current{
        .global-banner__box{
            transform: translateX(0);
            opacity: 1;
        }
    }
}

.marker-map{
    width: 84px;
    height: 109px;
    position: absolute;
    top: 49px;
    left: 577px;
    &:after{
        -webkit-animation: pulse 1s infinite linear;
        animation: pulse 1s infinite linear;
        content: '';
        position: absolute;
        left: 0;
        bottom: -9px;
        background-color: #fff;
        border-radius: 50%;
        height: 28px;
        margin: auto;
        right: 0;
        width: 28px;
        z-index: 1;
        -webkit-box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.7);
        box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.7);
        opacity: 0.5;
    }
    img{
        position: relative;
        z-index: 2;
        animation: marker 1.8s ease-out infinite;
    }
}

@keyframes marker{
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-8px);
    }
    80%{
        transform: translateY(4px);
    }
    100%{
        transform: translateY(0);
    }
}

.about{
    &-section{
        position: relative;
        &.animated{
            .about-list li{
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
    &-map{
        width: 64%;
        position: absolute;
        height: 100%;
        top:0;
        right: 0;
        background: url(../img/bg-map2.png) no-repeat;
        background-position: left top;
    }
    &-list{
        font-size: rem(24px);
        font-weight: 300;
        max-width:470px;
        padding-top:rem(110px);
        padding-bottom: 50px;
        img{
            max-width: 80px;
            margin-right: rem(40px);
            max-height: 100px;
        }
        li{
            display: flex;
            line-height: 1.2;
            align-items: center;
            margin-bottom: rem(50px);
            transition: all .5s linear;
            transition-delay: 0.3s;
            transform: translateX(-150px);
            opacity: 0;
            &:nth-child(2){
                transition-delay: 0.6s;
            }
            &:nth-child(3){
                transition-delay: 0.9s;
            }
            &:nth-child(4){
                transition-delay: 1.2s;
            }
        }
    }
}

.download{
    &-section{
        margin: rem(120px) 0 rem(160px);
        @media(max-width:767px){
            margin:30px 0 60px;
        }
        .container{
            max-width: 1130px;
        }
    }
    &-table{
        .row-th{
            line-height: 30px;
            font-size: 20px;
            font-weight: 300;
            text-transform: uppercase;
            justify-content: space-between;
            padding: 15px 0;
            border-bottom:2px solid #dedede;
            @media(max-width:767px){
                display: none;
            }
        }
        .col{
            padding: 0 30px;
            text-align: center;
            @media(max-width:1023px){
                padding: 0 15px;
            }
            &-name{
                max-width: 165px;
                flex:0 0 15%;
                img{
                    display: block;
                    margin: 0 auto;
                }
                .title{
                    display: block;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 300;
                }
            }
            &-text{
                flex:0 0 63%;
                max-width: 540px;
                font-weight: 300;
                @media(max-width:1023px){
                    max-width: 350px;
                }
                @media(max-width:767px){
                    flex:0 0 100%;
                    max-width: 100%;
                    padding-top: 20px;
                }
            }
            &-btn{
                flex:0 0 22%;
                max-width: 245px;
                @media(max-width:767px){
                    flex:0 0 100%;
                    margin-top: 25px;
                }
            }
        }
        .row-td{
            padding: 25px 0;
            justify-content: space-between;
            align-items: center;
            border-bottom:2px solid #dedede;
            @media(max-width:767px){
                flex-direction: column;
                justify-content: center;
            }
            .col-btn{
                padding: 0;
            }
            .col-text{
                font-size: 18px;
                text-align: left;
                @media(max-width:1023px){
                    font-size:16px;
                }
            }
            .col-name{
                padding: 0;
            }
        }
    }
}

.btn-download{
    font-size:rem(25px);
    border:0;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    &:after{
        content: "";
        background: #e92c5e;
        background: -webkit-gradient(left top, right top, color-stop(0%, #e92c5e), color-stop(100%, #d006ae));
        background: -webkit-linear-gradient(left, #e92c5e 0%, #d006ae 100%);
        background: -o-linear-gradient(left, #e92c5e 0%, #d006ae 100%);
        background: -webkit-gradient(linear, left top, right top, from(#e92c5e), to(#d006ae));
        background: linear-gradient(to right, #e92c5e 0%, #d006ae 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e92c5e', endColorstr='#d006ae', GradientType=1 );
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        transition: all .4s linear;
        z-index: 1;
    }
    &::before{
        content: "";
        border-radius: 8px;
        top:2px;
        left: 2px;
        right:2px;
        background: #fff;
        opacity: 0;
        transition: all .4s linear;
        bottom: 2px;
        z-index: 2;
        position: absolute;
    }
    span{
        position: relative;
        z-index: 3;
    }
    &:hover{
        background: #fff;
        color:#000;
        &::before{
            opacity: 1;
        }
    }
    @media(max-width:1100px){
        min-width: 200px;
    }
    @media(max-width:479px){
        font-size: 16px;
        padding: 0 15px;
    }
}

.model-window{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    display: none;
    z-index: 99;
    height: 100%;
    background: rgba(#000,0.7);
    .error{
        text-align: center;
        color:#e91650;
        font-size: 16px;
    }
    .btn{
        margin: 0 auto rem(40px);
        display: table;
    }
    .popup{
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        padding: 20px 60px;
        background: #fff;
        border-radius: 15px;
        width: 520px;
        height: 350px;
        margin: auto;
        box-shadow:0 6px 24px 0 rgba(#000000,0.17);
        @media(max-width:600px){
            width: 300px;
            height: 290px;
            padding: 20px 10px;
        }
        .item-input{
            margin-bottom: rem(45px);
            input{
                border:1px solid #000;
                line-height: 46px;
                height: 46px;
                width:100%;
                text-align: center;
                border-width: 0 0 1px;
            }
        }
        .title{
            display: block;
            text-align: center;
            color:#2b3942;
            font-weight: 400;
            margin-bottom: rem(25px);
            font-size:rem(25px);
            text-transform: uppercase;
        }
    }
}

@media(max-width:1700px){
    .about-map{
        background: url(../img/bg-map.png) no-repeat;
    }
}

@media(max-width:1260px){
    .about-list img{
        max-width: 50px;
        max-height: 80px;
    }
    .about-map{
        background-size: cover;
    }
    .global-banner__box{
        padding-bottom: 35px;
    }
    .marker-map {
        top: 0px;
        left: 392px;
    }
}
@media(max-width:1023px){
    .global-banner__item{
        height: 560px;
    }
    .marker-map {
        top: -3px;
        left: 359px;
    }
}
@media(max-width:767px){
    .btn-more{
        padding: 0 10px 0 40px;
        line-height: 50px;
        min-width: 200px;
    }
    .global-banner__box{
        max-width: 460px;
    }
    .about-list{
        padding-bottom: 0px;
    }
    .about-map{
        display: none;
    }
}
@media(max-width:639px){
    .global-banner__box {
        padding: 20px 20px 35px;
    }
    .global-banner__box .btn{
        margin-right: 0;
    }
    .btn-more {
        min-width: 190px;
    }
    .product-slider .slick-list,
    .provider-slider .slick-list{
        box-sizing: content-box;
    }
}
@media(max-width:479px){
    .btn-more {
        min-width: 160px;
        font-size: 16px;
    }
    .global-banner__box .title{
        font-size: 24px;
    }
}
@media(max-width:374px){
    .global-banner__box .btn-more{
        margin-bottom: 15px;
    }
    .global-banner__box .btn-more,
    .global-banner__box .btn{
        min-width: 100%;
    }
}