
*::-moz-selection {
    background:#c4e7ff;
}
  
*::selection {
    background: #c4e7ff;
} 
html{
    height: 100%;
}
.main{
    height: 100%;
}

body {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.44;
    font-size: 18px;
    color:#000;
}

.wow2{
    visibility: visible!important;
}
.wrapper{
    overflow: hidden;
}

.disable-hover,
.disable-hover * {
    pointer-events: none !important;
}
.loader-bg{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 99;
}

@media(min-width:320px){
    html{
        font-size:12px;
    }
}
@media(min-width:1024px){
    html{
        font-size: 14px;
    }
}
@media(min-width:1700px){
    html{
        font-size: 16px;
    }
}
@media(min-width:1800px){
    html{
        font-size:$browser-size;
    }
}