.row{
  display: flex;
}
.row-sb{
  display: flex;
  justify-content: space-between;
}

.text-center{
  text-align: center;
}
.container{
  max-width: 1330px;
  padding: 0 15px;
  margin: 0 auto;
}