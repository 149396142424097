.product{
    &-bg{
        padding-top:rem(70px);
        background: url(../img/bg-product.png) no-repeat;
        background-position: left top;
        display: flex;
        justify-content: flex-end;
    }
    &-section.animated{
        .product-slider{
            transform: translateX(0);
            opacity: 1;
        }
    }
    &-slider{
        max-width: 1340px;
        transform: translateX(500px);
        transition: all .6s linear;
        transition-delay: 0.2s;
        opacity: 0;
        .slick-arrow{
            position: absolute;
        }
        .slick-track{
            display: flex;
        }
        .slick-slide{
            height: auto;
            &.slick-current{
                & + .slick-slide + .slick-slide {
                    .fig-circle{
                        display: none;
                    }
                }
                .fig-circle{
                    span{
                        background: rgba(173,57,156,1);
                        background: -moz-linear-gradient(left, rgba(173,57,156,1) 0%, rgba(235,23,80,1) 100%);
                        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(173,57,156,1)), color-stop(100%, rgba(235,23,80,1)));
                        background: -webkit-linear-gradient(left, rgba(173,57,156,1) 0%, rgba(235,23,80,1) 100%);
                        background: -o-linear-gradient(left, rgba(173,57,156,1) 0%, rgba(235,23,80,1) 100%);
                        background: -ms-linear-gradient(left, rgba(173,57,156,1) 0%, rgba(235,23,80,1) 100%);
                        background: linear-gradient(to right, rgba(173,57,156,1) 0%, rgba(235,23,80,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ad399c', endColorstr='#eb1750', GradientType=1 );
                        &::before{
                            transform: rotate(180deg);
                            left:-4px;
                        }
                    }
                    &:after{
                        animation: pulse 2s infinite linear;
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        top: 0;
                        background-color: #fff;
                        border-radius: 50%;
                        margin: auto 0 auto -54px;
                        height: 108px;
                        width: 108px;
                        -webkit-box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.7);
                        box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.7);
                        z-index: -1;
                        opacity: 0.5;
                    }
                }
                .item{
                    background: rgba(245,58,108,1);
                    border-radius: 15px;
                    background: -moz-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,58,108,1)), color-stop(100%, rgba(208,6,174,1)));
                    background: -webkit-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                    background: -o-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                    background: -ms-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                    background: linear-gradient(to right, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
                }
            }
            > div{
                height: 100%;
            }
        }
        .slick-list{
            padding: 20px 300px 100px 0;
        }
        .item{
            padding: 2px;
            position: relative;
            border-radius: 15px;
            height: 100%;
            margin: 0 15px;
            max-width:485px;
            box-shadow: 0 0 46px 0 rgba(#000,0.23);
            font-size: rem(24px);
            text-align: center;
            font-weight: 300;
            background: #fff;
            img{
                margin: 0 auto rem(50px);
            }
            .box{
                height: 100%;
                border-radius: 15px;
                padding: 30px 50px 50px;
                line-height: 1.2;
                overflow: hidden;
                background: #fff;
            }
        }
    }
    &-section{
        .global-title{
            margin-bottom: rem(55px);
        }
    }
}

@media(max-width:1400px){
    .product-slider .item img{
        width: 100%;
    }
    .product-bg {
        padding-top: 8.88889rem;
    }
}

@media(max-width:1330px){
    .product-slider .item{
        max-width:385px;
    }
    .product-slider .slick-list{
        padding-right: 230px;
    }
    .product-bg {
        padding-bottom: 2.5rem;
        padding-top: 2rem;
    }
    .product-slider {
        max-width: 1040px;
    }
    .product-section .global-title{
        margin-bottom: 2rem;
    }
}
@media(max-width:1260px){
    .product-slider .slick-list{
        padding: 20px 0 100px;
    }
    .product-bg{
        padding: 2.5rem 15px;
    }
    .product-slider {
        max-width: 100%;
    }
    .product-slider .slick-slide{
        display: flex;
        justify-content: center;
    }
}

.partners{
    &-tabs{
        transform: translateY(300px);
        transition: all .5s linear;
        opacity: 0;
    }
    &-section{
        margin-bottom: 35px;
        &.animated{
            .partners-tabs{
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
    &-section{
        margin-bottom: 35px;
    }
    &-items{
        padding: 22px 40px;
        font-size: rem(24px);
        font-weight: 300;
        line-height: 1.3;
        background: #fff;
        position: relative;
        box-shadow: 0 0 46px 0 rgba(#000,0.23);
        background: #fff;
        border-radius: 0 0 15px 15px;
        .text{
            flex: 0 0 66%;
            padding-right: 30px;
            position: relative;
            &:after{
                content: '';
                width: 2px;
                position: absolute;
                top:0;
                right: 0;
                bottom: 0;
                background: rgba(110,110,110,0);
                background: -moz-linear-gradient(top, rgba(110,110,110,0) 0%, rgba(110,110,110,0) 0%, rgba(110,110,110,1) 21%, rgba(110,110,110,1) 80%, rgba(110,110,110,0) 100%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(110,110,110,0)), color-stop(0%, rgba(110,110,110,0)), color-stop(21%, rgba(110,110,110,1)), color-stop(80%, rgba(110,110,110,1)), color-stop(100%, rgba(110,110,110,0)));
                background: -webkit-linear-gradient(top, rgba(110,110,110,0) 0%, rgba(110,110,110,0) 0%, rgba(110,110,110,1) 21%, rgba(110,110,110,1) 80%, rgba(110,110,110,0) 100%);
                background: -o-linear-gradient(top, rgba(110,110,110,0) 0%, rgba(110,110,110,0) 0%, rgba(110,110,110,1) 21%, rgba(110,110,110,1) 80%, rgba(110,110,110,0) 100%);
                background: -ms-linear-gradient(top, rgba(110,110,110,0) 0%, rgba(110,110,110,0) 0%, rgba(110,110,110,1) 21%, rgba(110,110,110,1) 80%, rgba(110,110,110,0) 100%);
                background: linear-gradient(to bottom, rgba(110,110,110,0) 0%, rgba(110,110,110,0) 0%, rgba(110,110,110,1) 21%, rgba(110,110,110,1) 80%, rgba(110,110,110,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6e6e6e', endColorstr='#6e6e6e', GradientType=0 );
            }
        }
        .partners-fig{
            flex: 0 0 34%;
            display: flex;
            justify-content: space-between;
            padding: 0 30px;
            font-size: rem(24px);
            font-weight: 300;
            text-transform: uppercase;
            li{
                position: relative;
                display: flex;
                justify-content: flex-end;
                padding-bottom: 30px;
                text-align: center;
                transition: all .4s linear;
                flex-direction: column;
                color:$text-color;
                &:hover{
                    color: $red;
                    .circle{
                        opacity: 1;
                    }
                    img{
                        -webkit-filter: grayscale(0%);
                        -moz-filter: grayscale(0%);
                        -ms-filter: grayscale(0%);
                        -o-filter: grayscale(0%);
                        filter: grayscale(0%);
                        filter: none; /* Firefox 4+ */
                        filter: rgba(gray,0);
                    }
                }
            }
            img{
                cursor: pointer;
                transition: all .4s linear;
                display: block;
                max-height:70px;
                max-width: 80px;
                -webkit-filter: grayscale(100%);
                -moz-filter: grayscale(100%);
                -ms-filter: grayscale(100%);
                -o-filter: grayscale(100%);
                filter: grayscale(100%);
                -webkit-filter: gray;
                margin: 0 auto 30px;
            }
        }
    }
    &-title{
        position: relative;
        z-index: 2;
        li{
            flex: 0 0 25%;
            max-width:25%;
            text-align: center;
            cursor: pointer;
            display: flex;
            position: relative;
            padding: 5px 5px 10px;
            justify-content: center;
            border-radius: 15px 15px 0 0;
            img{
                position: relative;
                z-index: 1;
                transition: all .4s linear;
                -webkit-filter: grayscale(100%);
                -moz-filter: grayscale(100%);
                -ms-filter: grayscale(100%);
                -o-filter: grayscale(100%);
                filter: grayscale(100%);
                filter: gray; 
                display: block;
            }
            &.active{
                box-shadow: 0 0 46px 0 rgba(#000,0.23);
                img{
                    -webkit-filter: grayscale(0%);
                    -moz-filter: grayscale(0%);
                    -ms-filter: grayscale(0%);
                    -o-filter: grayscale(0%);
                    filter: grayscale(0%);
                    filter: none; /* Firefox 4+ */
                    filter: rgba(gray,0);
                }
                &:after{
                    content: "";
                    width: 100%;
                    background: #fff;
                    position: absolute;
                    bottom: -25px;
                    height: 50px;
                    left:0;
                }
            }
        }
    }
}

.circle{
    width: 55px;
    top: 15px;
    height: 55px;
    right: -20px;
    opacity: 0;
    transition: all .4s linear;
    border-radius: 50%;
    border:3px solid #fff;
    background: -moz-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,58,108,1)), color-stop(100%, rgba(208,6,174,1)));
    background: -webkit-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
    background: -o-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
    background: -ms-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
    background: linear-gradient(to right, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
    color:#fff;
    text-align: center;
    line-height: 52px;
    font-size: 20px;
    position: absolute;
}

.provider{
    &-section.animated{
        .provider-slider{
            transform: translateX(0);
            opacity: 1;
        }
    }
    &-section{
        margin-bottom: rem(200px);
    }
    &-bg{
        background: url(../img/bg-provider.png) no-repeat;
        display: flex;
        background-position: right top;
        padding: 65px 70px 0 0;
        justify-content: flex-end;
    }
    &-slider{
        max-width: 1580px;
        display: flex;
        transform: translateX(-800px);
        transition: all .6s linear;
        width:100%;
        transition-delay: 0.2s;
        // justify-content: center;
        opacity: 0;
        padding: 20px 0 80px 0;
        .slick-arrow{
            position: absolute;
        }
        .slick-track{
            display: flex;
        }
        &-bg{
            display: flex;
            justify-content: flex-end;
        }
        .slick-slide,.item{
            height: auto;
            &.slick-current{
                background: rgba(245,58,108,1);
                border-radius: 15px;
                background: -moz-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,58,108,1)), color-stop(100%, rgba(208,6,174,1)));
                background: -webkit-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                background: -o-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                background: -ms-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                background: linear-gradient(to right, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
                .fig-circle{
                    span{
                        background: rgba(173,57,156,1);
                        background: -moz-linear-gradient(left, rgba(173,57,156,1) 0%, rgba(235,23,80,1) 100%);
                        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(173,57,156,1)), color-stop(100%, rgba(235,23,80,1)));
                        background: -webkit-linear-gradient(left, rgba(173,57,156,1) 0%, rgba(235,23,80,1) 100%);
                        background: -o-linear-gradient(left, rgba(173,57,156,1) 0%, rgba(235,23,80,1) 100%);
                        background: -ms-linear-gradient(left, rgba(173,57,156,1) 0%, rgba(235,23,80,1) 100%);
                        background: linear-gradient(to right, rgba(173,57,156,1) 0%, rgba(235,23,80,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ad399c', endColorstr='#eb1750', GradientType=1 );
                        &::before{
                            transform: rotate(180deg);
                            left:-4px;
                        }
                    }
                    &:after{
                        animation: pulse 2s infinite linear;
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        top: 0;
                        background-color: #fff;
                        border-radius: 50%;
                        margin: auto 0 auto -54px;
                        height: 108px;
                        width: 108px;
                        -webkit-box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.7);
                        box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.7);
                        z-index: -1;
                        opacity: 0.5;
                    }
                }
                // .item{
                //     background: rgba(245,58,108,1);
                //     border-radius: 15px;
                //     background: -moz-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                //     background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,58,108,1)), color-stop(100%, rgba(208,6,174,1)));
                //     background: -webkit-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                //     background: -o-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                //     background: -ms-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                //     background: linear-gradient(to right, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
                //     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
                // }
            }
            > div{
                height: 100%;
            }
        }
        // .slick-list{
        //     padding: 20px 0 80px 0;
        // }
        .item{
            padding: 2px;
            border-radius: 15px;
            height: 100%;
            margin: 0 15px;
            position: relative;
            max-width:485px;
            box-shadow: 0 0 46px 0 rgba(#000,0.23);
            font-size: rem(24px);
            text-align: center;
            font-weight: 300;
            background: #fff;
            img{
                margin: 0 auto rem(50px);
                max-height:160px;
            }
            .box{
                height: 100%;
                border-radius: 15px;
                padding: 30px 30px 60px;
                line-height: 1.1;
                overflow: hidden;
                background: #fff;
            }
        }
    }
}


@media(max-width:1600px){
    .provider-section{
        margin-bottom: 8rem;
    }
    .provider-slider{
        max-width: 1300px;
        width: 100%;
    }
    .provider-slider .item{
        max-width:385px;
    }
    .provider-slider .slick-list {
        padding: 35px 0 80px 0;
    }
}
@media(max-width:1330px){
    .provider-section{
        margin-bottom: 6rem;
    }
    .partners-items .partners-fig li{
        padding-bottom: 10px;
    }
    .provider-bg{
        padding: 65px 15px;
        justify-content: center;
    }
    .provider-slider .slick-slide > div{
        display: flex;
        justify-content: center;
    }
    .provider-slider{
        justify-content: center;
    }
}

@media(max-width:1260px){
    .partners-title li img {
        width: 100%;
        max-width: 160px;
        height: 100%;
    }
    .provider-bg{
        background: rgba(245,58,108,1);
        background: -moz-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,58,108,1)), color-stop(100%, rgba(208,6,174,1)));
        background: -webkit-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: -o-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: -ms-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: linear-gradient(to right, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
    }
}

@media(max-width:1200px){
    .provider-bg{
        padding: 20px 15px;
    }
    .partners-items .partners-fig img{
        max-width:50px;
    }
    .partners-items .partners-fig{
        font-size:16px;
    }
}
@media(max-width:1023px){
    .product-slider .slick-list {
        padding: 20px 0 60px;
    }
    .product-section .global-title{
        margin-bottom: 0;
    }
    .partners-title li.active:after {
        content: "";
        width: 100%;
        background: #fff;
        position: absolute;
        bottom: -22px;
        height: 40px;
        left: 0;
    }
}
@media(max-width:959px){
    .product-slider .item{
        max-width:300px;
        .box{
            padding: 30px 40px 50px;
        }
    }
    .provider-slider .item {
        max-width: 300px;
    }
    .partners-title li img {
        width: 100%;
        max-width: 110px;
        height: 100%;
    }
    .partners-items .partners-fig{
        display: none;
    }
    .partners-items .text{
        flex:0 0 100%;
        max-width:100%;
        &:after{
            display: none;
        }
    }
    .provider-slider .item img {
        max-height: 130px;
    }
    .btn-more i{
        width:35px;
        height: 35px;
        &:before {
            width: 10px;
            height: 16px;
        }
    }
}
@media(max-width:767px){
    .product-slider .item {
        max-width:260px;
        .box {
            padding: 25px 20px 40px;
        }
    }
    .partners-items{
        padding: 20px;
    }
    .provider-slider .item img{
        max-height: 90px;
    }
    .provider-slider .item {
        max-width:260px;
        .box {
            padding: 25px 20px 40px;
        }
    }
}
@media(max-width:639px){
    .partners-title li img {
        max-width: 90px;
    }
    .product-slider{
    }
    .slick-arrow{
        border:0;
        background: none;
        position: absolute;
        top:0;
        bottom: 0;
        margin: auto;
        z-index: 2;
        width:40px;
        height: 40px;
        cursor: pointer;
        padding: 0;
        border-radius: 50%;
        font-size: 0;
        background: rgba(245,58,108,1);
        background: -moz-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,58,108,1)), color-stop(100%, rgba(208,6,174,1)));
        background: -webkit-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: -o-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: -ms-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: linear-gradient(to right, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
        left:0;
        border:1px solid #fff;
        &.slick-prev{
            &:after{
                transform: rotate(180deg);
            }
        }
        &:after{
            content: "";
            width:11px;
            height: 20px;
            background: url(../img/svg/arrow-r.svg) no-repeat;
            position: absolute;
            left:0;
            right: 0;
            margin: auto;
            top:0;
            bottom: 0;
        }
        &.slick-next{
            right: 0;
            left:auto;
        }
    }
}
@media(max-width:400px){
    .product-slider .item,
    .provider-slider .item{
        max-width: 240px;
    }
    .partners-title li img{
        max-width: 80px;
    }
}
@media(max-width:374px){
    .product-slider .item,
    .provider-slider .item{
        max-width: 210px;
    }
    .slick-arrow{
        width: 30px;
        height: 30px;
    }
    .product-slider .item .box {
        padding: 25px 10px 40px;
    }
    .provider-slider .item .box{
        padding: 25px 10px 40px;
    }
}