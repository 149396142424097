.motivation{
    &-slider{
        border-radius: 10px;
        margin-top: 140px;
        background: #dadada;
        margin-bottom: 15px;
        @media(max-width:1280px){
            margin-top: 140px;
        }
        @media(max-width:1260px){
            margin-top: 110px;
        }
        @media(max-width:940px){
            margin-top:100px;
        }
        @media(max-width:540px){
            margin: 90px -15px 15px;
            border-radius:0;
        }
        .slick-dots{
            position: absolute;
            bottom: 32px;
            right: 33px;
            justify-content: center;
            display: flex;
            z-index: 4;
            width:180px;
            @media(max-width:940px){
                width: 108px;
                right:33px;
            }
            @media(max-width:670px){
                margin: 0 auto;
                right:0;
                left:0;
                bottom: 20px;
            }
            li{
                border:2px solid #747474;
                border-radius:50%;
                width:12px;
                margin:0 7px;
                height:12px;
                cursor: pointer;
                @media(max-width:940px){
                    margin: 0 3px;
                }
                @media(max-width:670px){
                    border:2px solid rgba(#fff,0.6);
                }
                &.slick-active{
                    background: #747474;
                    @media(max-width:670px){
                        background: rgba(#fff,0.6);
                    }
                }
                button{
                    border:0;
                    font-size: 0;
                    background: none;
                }
            }
        }
        &_wrap{
            position: relative;
        }
        &_nav{
            position: absolute;
            width:215px;
            z-index: 2;
            height:28px;
            bottom: 22px;
            right:10px;
            @media(max-width:940px){
                width:155px;
            }
        }
        &_prev,&_next{
            position: absolute;
            width:15px;
            cursor: pointer;
            height: 26px;
        }
        &_prev{
            left:0;
            background: url(../img/svg/arrow-left_m.svg) no-repeat;
            border:0;
            @media(max-width:670px){
                display: none;
            }
            &:after{
                display: none;
            }
            &:hover{
                background: url(../img/svg/arrow-left_m_h.svg) no-repeat;
            }
        }
        &_next{
            right:0;
            background: url(../img/svg/arrow-right_m.svg) no-repeat;
            border:0;
            @media(max-width:670px){
                display: none;
            }
            &:after{
                display: none;
            }
            &:hover{
                background: url(../img/svg/arrow-right_m_h.svg) no-repeat;
            }
        }
        .item{
            align-items: stretch;
            border-radius: 10px;
            padding-right: 220px;
            position: relative;
            min-height: 390px;
            overflow: hidden;
            display:flex!important;
            justify-content: space-between;
            @media(max-width:1280px){
                padding-right: 80px;
                min-height: 360px;
            }
            @media(max-width:620px){
                padding-right: 40px;
            }
            @media(max-width:540px){
                border-radius:0;
                padding-right: 15px;
            }
            .img{
                margin-top: 0;
                display:flex;
                align-items: flex-end;
                .mob-img{
                    display: none;
                }
                @media(max-width:860px){
                    position: relative;
                    z-index: 2;
                }
                @media(max-width:540px){
                    align-items: flex-start;
                    z-index: 0;
                    &.first-img{
                        z-index: 3;
                        .mob-img{
                            display: block;
                            max-width: 250px;
                            position: relative;
                            padding-top: 15px;
                            margin-right: -85px;
                            z-index: 3;
                        }
                    }
                    &.second-img{
                        z-index: 3;
                        .mob-img{
                            display: block;
                            max-width: 240px;
                            position: relative;
                            padding-top: 15px;
                            margin-right: -55px;
                            z-index: 3;
                        }
                    }
                    img{
                        display: none;
                    }
                    .mob-img{
                        display: block;
                        padding-top: 15px;
                    }
                }
                @media(max-width:420px){
                    .mob-img{
                       max-width:220px;
                    }
                }
                @media(max-width:400px){
                    .mob-img{
                       max-width:180px;
                    }
                }
                @media(max-width:374px){
                    .mob-img{
                        max-width:150px;
                    }
                }
            }
            &:after{
                content: "";
                width: 560px;
                background: #df0f68;
                position: absolute;
                top:0;
                right:8%;
                height: 100%;
                transform: skew(-40deg);
                @media(max-width:1280px){
                    width:480px;
                }
                @media(max-width:1180px){
                    width: 400px;
                }
                @media(max-width:940px){
                    width: 330px;
                    transform: skew(-37deg);
                }
                @media(max-width:670px){
                    right:6%;
                    transform: skew(-37deg);
                }
                @media(max-width:480px){
                    width:250px;
                    right:13%;
                }
                @media(max-width:440px){
                    width:250px;
                    right:5%;
                }
                @media(max-width:400px){
                    right:-1%;
                    transform: skew(-30deg);
                }
                @media(max-width:374px){
                    width: 250px;
                    right: 4%;
                }
                @media(max-width:355px){
                    width: 210px;
                    right: 3%;
                    transform: skew(-24deg);
                }
            }
            img{
                display: block;
                @media(max-width:1280px){
                    padding-top: 15px;
                }
                @media(max-width:1180px){
                    max-width: 420px;
                    max-height: 350px;
                }
                @media(max-width:940px){
                    max-width: 340px;
                    max-height: 300px;
                }
            }
        }
        .info{
            width: 380px;
            z-index: 2;
            display: flex;
            flex-direction: column;
            padding-top:90px;
            justify-content: center;
            @media(max-width:1280px){
                width:360px;
            }
            @media(max-width:940px){
                padding-top: 30px;
                width: 260px;
            }
            @media(max-width:440px){
                padding-top:0;
            }
            .title{
                display:block;
                color:#fff;
                font-size: 30px;
                font-weight: 700;
                text-transform: uppercase;
                @media(max-width:1280px){
                    font-size:26px;
                }
                @media(max-width:940px){
                    font-size: 22px;
                }
                @media(max-width:415px){
                    font-size:20px;
                    letter-spacing: -0.5px;
                }
            }
            .text{
                font-size: 20px;
                color:#fff;
                font-weight: 300;
                @media(max-width:670px){
                    font-size:14px;
                }
            }
            .btn{
                border:2px solid #fff;
                font-size:24px;
                text-align: center;
                margin-top: 22px;
                line-height: 60px;
                width: 300px;
                background: #dc0f67;
                @media(max-width:1280px){
                    width:230px;
                    font-size: 18px;
                    line-height: 42px;
                }
                @media(max-width:540px){
                    width:180px;
                    position: absolute;
                    bottom: 45px;
                    left:0;
                    right:0;
                    margin: 0 auto;
                    font-size: 13px;
                    line-height: 36px;
                }
            }
        }
    }
    &-target{
        padding: 25px 10px;
        box-shadow: 0 4px 27px 0 rgba(#000,0.27);
        border-radius: 10px;
        margin-bottom: 15px;
        @media(max-width:767px){
            margin-bottom: 40px;
        }
        .download{
            font-size: 20px;
            text-align: center;
            color:#000;
            line-height: 50px;
            margin: 0 auto;
            display: table;
            position: relative;
            min-width: 360px;
            background: #fff;
            box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.24);
            font-weight:400;
            border:2px solid #dc0f67;
            &:after{
                content:"";
                width:15px;
                position: absolute;
                right:6%;
                top:0;
                bottom: 0;
                margin: auto;
                height: 26px;
                transform: rotate(90deg);
                background: url(../img/svg/arrow-right_m_h.svg) no-repeat;
                @media(max-width:767px){
                    height:18px;
                    width:8px;
                }
            }
            &:hover{
                box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.4);
            }
            span{
                color:#ba096e;
                display: inline-block;
                margin-left: 10px;
            }
            @media(max-width:767px){
                font-size:16px;
                min-width:280px;
                letter-spacing: -0.5px;
                line-height: 44px;
                span{
                    margin-left: 0;
                }
            }
        }
        ul{
            display: flex;
            margin-bottom: 30px;
            justify-content:space-evenly;
            position: relative;
            @media(max-width:767px){
                flex-wrap:wrap;
                margin-bottom: 0;
                &:after{
                    content: "";
                    width: 100%;
                    height: 1px;
                    border-bottom: 1px dashed #d5d5d5;
                    position: absolute;
                    top: -35px;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                }
                &:before{
                    content: "";
                    width: 1px;
                    border-left: 1px dashed #d5d5d5;
                    position: absolute;
                    top: 0px;
                    left: 0;
                    bottom: 20px;
                    right: 0;
                    margin: auto;
                }
            }
        }
        li{
            flex:0 0 25%;
            max-width: 230px;
            text-align: center;
            font-size: 13px;
            text-transform: uppercase;
            @media(max-width:767px){
                flex:0 0 50%;
                max-width: 50%;
                .box{
                    margin-bottom: 35px;
                }
            }
            @media(max-width:440px){
                font-size: 10px;
            }
            .img{
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background: rgba(235,23,80,1);
                background: -moz-linear-gradient(top, rgba(235,23,80,1) 0%, rgba(210,8,129,1) 100%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(235,23,80,1)), color-stop(100%, rgba(210,8,129,1)));
                background: -webkit-linear-gradient(top, rgba(235,23,80,1) 0%, rgba(210,8,129,1) 100%);
                background: -o-linear-gradient(top, rgba(235,23,80,1) 0%, rgba(210,8,129,1) 100%);
                background: -ms-linear-gradient(top, rgba(235,23,80,1) 0%, rgba(210,8,129,1) 100%);
                background: linear-gradient(to bottom, rgba(235,23,80,1) 0%, rgba(210,8,129,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb1750', endColorstr='#d20881', GradientType=0 );
                border:6px solid #fff;
                box-shadow: 0 4px 27px 0 rgba(#000,0.37);
                overflow: hidden;
                margin: 0 auto 10px;
                @media(max-width:767px){
                    width: 110px;
                    height: 110px;
                }
                img{
                    width: 100%;
                }
            }
            .title{
                font-size: 20px;
                display: block;
                font-weight: 700;
                display: block;
            }
        }
    }
    &-section{
        padding: 15px 0 15px 50px;
        border:2px solid #c70f58;
        box-shadow: 0 4px 27px 0 rgba(#000,0.27);
        border-radius: 10px;
        margin-bottom: 5rem;
        @media(max-width:940px){
            padding-left: 40px;
            margin-bottom: 3.5rem;
        }
        @media(max-width:767px){
            border:0;
            border-radius: 0;
            background: none;
            padding:0;
            box-shadow: none;
            .thead{
                display: none;
            }
        }
        .title{
            display: block;
            text-align: center;
            color:#000;
            font-size: 22px;
            text-transform: uppercase;
            margin:0 auto 25px;
            position: relative;
            font-weight: 400;
            padding-left: 60px;
            display: table;
            &:after{
                content: "";
                width: 35px;
                height: 35px;
                background: url(../img/svg/box-p.svg) no-repeat;
                position: absolute;
                left:0;
                top:-4px;
            }
            @media(max-width:767px){
                font-size:18px;
                padding:0 50px;
                &:after{
                    left: 8px;
                    top: 3px;
                }
            }
        }
    }
    &-table{
        .th{
            min-width: 110px;
            text-align: center;
            font-size:16px;
            line-height: 45px;
            text-transform: uppercase;
            &.name{
                min-width: 230px;
            }
            &.desk{
                flex:1;
                padding-left: 55px;
                text-align: left;
            }
        }
        .td-wrap{
            width: 340px;
            margin: -1px 0;
            background: rgba(236,0,142,1);
            background: -moz-linear-gradient(left, rgba(236,0,142,1) 0%, rgba(235,23,80,1) 100%);
            background: -webkit-gradient(left top, right top, color-stop(0%, rgba(236,0,142,1)), color-stop(100%, rgba(235,23,80,1)));
            background: -webkit-linear-gradient(left, rgba(236,0,142,1) 0%, rgba(235,23,80,1) 100%);
            background: -o-linear-gradient(left, rgba(236,0,142,1) 0%, rgba(235,23,80,1) 100%);
            background: -ms-linear-gradient(left, rgba(236,0,142,1) 0%, rgba(235,23,80,1) 100%);
            background: linear-gradient(to right, rgba(236,0,142,1) 0%, rgba(235,23,80,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec008e', endColorstr='#eb1750', GradientType=1 );
            position: relative;
            &:after{
                content: "";
                border-top:32px solid transparent;
                border-bottom: 32px solid transparent;
                border-left:16px solid #ec1750;
                @media(max-width:767px){
                    display:none;
                }
            }
            .name{
                display: flex;
                align-items: center;
                flex:0 0 100%;
                max-width: 230px;
                padding:0 23px 0 50px;
                height:64px;
                text-align: center;
                justify-content: center;
                color:#fff;
                @media(max-width:767px){
                    max-width:70%;
                    flex:0 0 70%;
                    padding-left: 10px;
                }
                @media(max-width:440px){
                    max-width:64%;
                    flex:0 0 64%;
                }
                .name{
                    padding:0;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 20px;
                    @media(max-width:767px){
                        font-size: 15px;
                        line-height: 1.1;
                        max-width:100%;
                    }
                }
            }
            .balls{
                min-width: 110px;
                text-align: center;
                font-size:30px;
                color:#fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 300;
                @media(max-width:940px){
                    font-size:24px;
                }
                @media(max-width:767px){
                    font-size:30px;
                }
                @media(max-width:440px){
                    max-width:100%;
                    min-width:95px;
                    font-size:26px;
                }
            }
            .img{
                width:72px;
                height: 72px;
                border-radius: 50%;
                border:5px solid #ec008c;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left:-30px;
                top:-4px;
                background: #fff;
                @media(max-width:767px){
                    display: none;
                }
                img{
                    width: 100%;
                    max-height:38px;
                    max-width:42px;
                }
            }
            .mob{
                display: none;
            }
            @media(max-width:767px){
                width: 100%;
                .mob{
                    max-width:40px;
                    display: block;
                    left: -6px;
                    position: relative;
                }
            }
        }
        .tbody{
            border:1px solid #bababa;
            border-width: 0 0 1px;
            margin-bottom: 15px;
            @media(max-width:767px){
                flex-wrap: wrap;
                border-radius:10px;
                overflow: hidden;
                border:0;
                background: #fff;
                box-shadow: 0 4px 17px 0 rgba(#000,0.27);
            }
            &:nth-child(2){
                border-width: 1px 0;
            }
        }
        .td.balls{
            position: relative;
            &::before{
                content: "";
                width: 5px;
                height: 100%;
                background: url(../img/ellipse.png) repeat-y;
                position: absolute;
                left:0;
                top:0;
                @media(max-width:767px){
                    width:1px;
                }
            }
            &::after{
                content: "";
                width: 19px;
                height: 19px;
                background: url(../img/svg/balls.svg) no-repeat;
                position: absolute;
                top:0;
                bottom:0;
                margin: auto;
                right:-3px;
                @media(max-width:767px){
                    right:7px;
                }
                @media(max-width:600px){
                    right:-2px;
                }
            }
        }
        .td.desk{
            flex:1;
            padding-left: 55px;
            text-align: left;
            display: flex;
            padding-right:30px;
            line-height: 19px;
            font-size:18px;
            align-items: center;
            @media(max-width: 1280px){
                font-size: 16px;
                line-height: 1.1;
            }
            @media(max-width:940px){
                padding-right:10px;
                padding-left:30px;
            }
            @media(max-width:767px){
                padding:10px;
                text-align: center;
            }
        }
    }
}

.first-img{
    position: relative;
    z-index: 2;
    padding: 0 20px 15px 15px;
}

.motivation-slider.num2 .slick-dots li{
    border-color:#fff;
    &.slick-active {
        background: #fff;
    }
}

.motivation-slider.num2 + .motivation-slider_nav{
    .motivation-slider_prev {
        background: url(../img/svg/arrow-left_w.svg) no-repeat;
    }
    .motivation-slider_next {
        background: url(../img/svg/arrow-right_w.svg) no-repeat;
    }
}
.slide2{
    background: url(../img/slider-img7.jpg) no-repeat;
    background-size:cover;
    background-position: left top;
    &:after{
        display: none;
    }
    @media(max-width:767px){
        padding-right: 0!important;
    }
    @media(max-width:670px){
        padding-right: 0!important;
        background: url(../img/slider7-mob.jpg) no-repeat;
        background-size: cover;
    }
    .info{
        padding-top: 210px;
        width: 370px;
        margin-left: auto;
        @media(max-width:1024px){
            padding-top: 155px;
        }
        @media(max-width:767px){
            width: 240px;
        }
    }
    .info .btn{
        background: transparent;
    }
}