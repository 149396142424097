.header{
    position: fixed;
    left:0;
    top:0;
    z-index: 9;
    width: 100%;
    padding: 25px 0;
    .row-sb{
        padding: 0 30px;
        border-radius: 10px;
        position: relative;
        background: rgba(245,58,108,1);
        background: -moz-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,58,108,1)), color-stop(100%, rgba(208,6,174,1)));
        background: -webkit-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: -o-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: -ms-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: linear-gradient(to right, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
        box-shadow: 0 8px 49px 0 rgba(#000,0.6);
    }
    ul{
        display: flex;
        li{
            position: relative;
            align-items: center;
            display: flex;
            margin: 0 15px;
            padding: 0 5px;
            &:after{
                transition: width .4s linear;
                content: "";
                width: 0;
                position: absolute;
                bottom: 0;
                left: 0;
                margin: auto;
                right: 0;
                background: #fff;
                height: 3px;
            }
            &:hover:after{
                width: 100%;
            }
        }
        a{
            color:#fff;
        }
    }
    .logo{
        padding: 12px 0;
        &:after{
            content: "";
            width: 37px;
            height: 87px;
            background: url(../img/shadow-l.png) no-repeat;
            position: absolute;
            top: 5px;
            left: 214px;
        }
    }
    &-language{
        width:50px;
        font-weight: 300;
        font-size: 18px;
        padding: 0 5px;
        display: flex;
        align-items: center;
        margin-left: 50px;
        justify-content: center;
        height: 100%;
        position: relative;
        &::before{
            content: "";
            width: 37px;
            height: 87px;
            background: url(../img/shadow-r.png) no-repeat;
            position: absolute;
            top: 5px;
            right: 53px;
        }
        &:hover{
            &:after{
                transform: rotate(0deg);
                top:42px;
            }
            ul{
                opacity: 1;
                pointer-events: auto;
            }
        }
        ul{
            position: absolute;
            flex-direction: column;
            transition: all .4s linear;
            padding: 25px 0 0;
            top: 35px;
            opacity: 0;
            left:0;
            right:0;
            pointer-events: none;
            background: rgba(255,255,255,0);
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 59%, rgba(255,255,255,0.5) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(59%, rgba(255,255,255,0.2)), color-stop(100%, rgba(255,255,255,0.5)));
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 59%, rgba(255,255,255,0.5) 100%);
            background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 59%, rgba(255,255,255,0.5) 100%);
            background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 59%, rgba(255,255,255,0.5) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 59%, rgba(255,255,255,0.5) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
            li{
                margin: 3px 0;
                text-align: center;
                &:after{
                    display: none;
                }
            }
            a{
                display: block;
                width: 100%;
                color:$text-color;
            }
        }
        &:after{
            content: "";
            width: 12px;
            height: 10px;
            background: url(../img/svg/accordion-arrow-w.svg) no-repeat;
            position: absolute;
            transition: all .4s linear;
            right: -3px;
            transform: rotate(180deg);
            top: 37px;
        }
        li{
            border-top:0;
            padding-top: 0px;
            padding-bottom: 3px;
            text-align: center;
        }
        a{
            color:$text-color;
            &:hover{
                color:#fff;
            }
        }
        &__active{
            color:#fff;
        }
    }
}
.header-nav{
    font-weight: 500;
    font-size: rem(20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-menu{
    width: 30px;
    height: 30px;
    z-index: 9;
    pointer-events: auto;
    position: relative;
    transition: all .5s $easing;
    cursor: pointer;
    display: none;
    .line {
      position: absolute;
      right:0;
      top: 50%;
      background: #fff;
      width: 30px;
      height: 4px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .line:after {
      content: "";
      width: 30px;
      height: 4px;
      background: #fff;
      transition: all .3s linear;
      position: absolute;
      right:0;
      top: 9px;
    }
    .line:before {
      content: "";
      background: #fff;
      width: 30px;
      height: 4px;
      transition: all .3s linear;
      position: absolute;
      right:0;
      top: -9px;
    }
}
.btn-menu.show .line{
    height: 0;
}

.btn-menu.show .line:before {
    transform: rotate(45deg);
    background: #fff;
    top:-2px;
    width: 26px;
}

.btn-menu.show .line:after {
    transform: rotate(-45deg);
    background: #fff;
    top:-2px;
    width: 26px;
}

@media(max-width:1260px){
    .header{
        padding: 15px 0;
    }
    .header .logo{
        img{
            width: 140px;
        }
    }
    .header .row-sb {
        padding: 0 15px;
    }
    .header-language:after{
        top:28px;
    }
    .header-language:hover:after {
        top: 32px;
    }
    .header .logo:after,
    .header-language::before{
        display: none;
    }
    .header-language{
        margin-left: 0;
    }
}
@media(max-width:959px){
    .header .logo{
        img{
            width: 90px;
        }
    }
    .header ul li{
        margin: 0 6px;
    }
    .header-language:after{
        top:23px;
    }
    .header-language:hover:after {
        top: 27px;
    }
}
@media(max-width:800px){
    .header .row-sb{
        display: flex;
        align-items: center;
    }
    .header-nav{
        position: fixed;
        max-height: 0;
        border-top:1px solid transparent;
        transition: all .4s linear;
        overflow: hidden;
        background: rgba(245,58,108,1);
        background: -moz-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,58,108,1)), color-stop(100%, rgba(208,6,174,1)));
        background: -webkit-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: -o-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: -ms-linear-gradient(left, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        background: linear-gradient(to right, rgba(245,58,108,1) 0%, rgba(208,6,174,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
        left: 15px;
        right: 15px;
        border-radius: 0 0 15px 15px;
        top: 72px;
        display: flex;
        flex-direction: column;
        li{
            line-height: 46px;
            text-transform: uppercase;
            text-align: center;
            font-size: 18px;
            opacity: 0;
            transition: all .4s linear;
            justify-content: center;
            &:hover:after{
                display: none;
            }
        }
        ul{
            flex-direction: column;
        }
        &.show{
            max-height: 600px;
            border-top:1px solid #fff;
            li{
                transition-delay: 0.5s;
                opacity: 1;
            }
            .header-language__active{
                opacity: 1;
                transition-delay: 0.5s;
            }
        }
    }
    .btn-menu{
        display: block;
    }
    .header{
        padding: 15px 0;
    }
    .header-language{
        width: 100%;
        display: flex;
        &__active{
            transition: all .4s linear;
            margin: 0 10px;
            opacity: 0;
        }
        &:after{
            display: none;
        }
        &:hover{
            ul{
                background: none;
            }
        }
        ul{
            position: static;
            margin: 0;
            flex-direction: row;
            opacity: 1;
            padding: 0;
            background: none;
        }
    }
}

.header-nav.show{
    .btn-prize{
        opacity: 1;
        transition-delay: 0.5s;
    }
    .btn-box{
        opacity: 1;
        transition-delay: 0.5s;
    }
}

.btn-prize{
    display: inline-block;
    padding-right: 38px;
    position: relative;
    min-width: 185px;
    border-radius: 10px;
    border:1px solid #fff;
    color:#fff;
    font-size: 20px;
    font-weight: 300;
    text-align: right;
    line-height: 40px;
    transition: all .4s linear;
    box-shadow: 0 6px 16px 0 rgba(#000000,0.24);
    &:hover{
        box-shadow: 0 6px 20px 0 rgba(#000000,0.6);
    }
    @media(max-width:1100px){
        padding-right: 10px;
        min-width: 105px;
        font-size:16px;
    }
    @media(max-width:800px){
        opacity: 0;
    }
    &:after{
        content: "";
        width: 40px;
        height: 26px;
        background: url(../img/svg/prize.svg) no-repeat;
        position: absolute;
        left:11px;
        top:0;
        bottom: 0;
        margin: auto;
        @media(max-width:1100px){
            width: 34px;
            height: 20px;
        }
    }
}

.btn-box{
    display: inline-block;
    padding-right: 20px;
    position: relative;
    text-align: right;
    min-width: 105px;
    border-radius: 10px;
    border:1px solid #fff;
    color:#fff;
    font-size: 20px;
    margin-left: 22px;
    transition: all .4s linear;
    font-weight: 300;
    line-height: 40px;
    border-left:0;
    box-shadow: 0 6px 16px 0 rgba(#000000,0.24);
    &:hover{
        box-shadow: 0 6px 20px 0 rgba(#000000,0.6);
    }
    @media(max-width:1100px){
        padding-right: 10px;
        min-width: 80px;
        font-size:16px;
    }
    @media(max-width:800px){
        opacity: 0;
    }
    &:after{
        content: "";
        width: 40px;
        height: 40px;
        background: url(../img/svg/box.svg) no-repeat;
        position: absolute;
        left:-11px;
        top:0;
        bottom: 0;
        margin: auto;
    }
}