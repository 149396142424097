* {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body, figure {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

*:focus {
  outline: none; }

a {
  text-decoration: none; }

a:active,
a:hover {
  outline: 0; }

small {
  font-size: 80%; }

img {
  border: 0; }

address {
  font-style: normal; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  resize: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

ul, ol {
  margin: 0;
  padding-left: 0;
  list-style-type: none; }

td, th {
  padding: 0; }

p {
  margin: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@font-face {
  font-family: 'russo_oneregular';
  src: url("../fonts/Russo/9928-webfont.woff2") format("woff2"), url("../fonts/Russo/9928-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

*::-moz-selection {
  background: #c4e7ff; }

*::selection {
  background: #c4e7ff; }

html {
  height: 100%; }

.main {
  height: 100%; }

body {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.44;
  font-size: 18px;
  color: #000; }

.wow2 {
  visibility: visible !important; }

.wrapper {
  overflow: hidden; }

.disable-hover,
.disable-hover * {
  pointer-events: none !important; }

.loader-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 99; }

@media (min-width: 320px) {
  html {
    font-size: 12px; } }

@media (min-width: 1024px) {
  html {
    font-size: 14px; } }

@media (min-width: 1700px) {
  html {
    font-size: 16px; } }

@media (min-width: 1800px) {
  html {
    font-size: 18px; } }

.row {
  display: flex; }

.row-sb {
  display: flex;
  justify-content: space-between; }

.text-center {
  text-align: center; }

.container {
  max-width: 1330px;
  padding: 0 15px;
  margin: 0 auto; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  margin-bottom: 0;
  margin-top: 0;
  display: block; }

h2, .h2 {
  font-size: 2.22222rem;
  color: #e92c5d;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase; }

h3, .h3 {
  font-size: 1.11111rem;
  font-family: 'next_art';
  font-weight: normal;
  text-transform: uppercase; }

h4, .h4 {
  font-size: 1.55556rem; }

.section {
  padding-top: 4.16667rem; }

.global-title {
  padding: 27px 0; }
  .global-title .text {
    font-size: 1.66667rem;
    font-weight: 300;
    text-align: center;
    max-width: 560px;
    margin: 30px auto 0; }

.title-small_box {
  color: #fff;
  text-align: center;
  max-width: 860px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 1.77778rem;
  margin-bottom: 3.05556rem;
  display: block; }

.fig-circle {
  width: 75px;
  height: 75px;
  position: absolute;
  bottom: -37px;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  z-index: 2; }
  .fig-circle span {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    display: inline-block;
    border: 14px solid #fff;
    -webkit-box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.4);
    background: #b1b1b1;
    background: -moz-linear-gradient(left, #b1b1b1 0%, #8a8a8a 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, #b1b1b1), color-stop(100%, #8a8a8a));
    background: -webkit-linear-gradient(left, #b1b1b1 0%, #8a8a8a 100%);
    background: -o-linear-gradient(left, #b1b1b1 0%, #8a8a8a 100%);
    background: -ms-linear-gradient(left, #b1b1b1 0%, #8a8a8a 100%);
    background: linear-gradient(to right, #b1b1b1 0%, #8a8a8a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b1b1b1', endColorstr='#8a8a8a', GradientType=1 ); }
    .fig-circle span::before {
      content: "";
      width: 14px;
      height: 24px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      transition: all .4s linear;
      bottom: 0;
      top: 0;
      margin: auto;
      background: url(../img/svg/arrow-r.svg) no-repeat; }

.btn-more {
  font-size: 1.66667rem;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  line-height: 62px;
  display: inline-block;
  min-width: 250px;
  box-shadow: 0 18px 59px 0 rgba(0, 0, 0, 0.4);
  padding: 0 18px 0 68px;
  font-weight: 300;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  transition: all .4s linear; }
  .btn-more i {
    border-radius: 50%;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    width: 50px;
    height: 50px;
    background: #ad399c;
    background: -webkit-gradient(left top, right top, color-stop(0%, #ad399c), color-stop(100%, #eb1750));
    background: -webkit-linear-gradient(left, #ad399c 0%, #eb1750 100%);
    background: -o-linear-gradient(left, #ad399c 0%, #eb1750 100%);
    background: -webkit-gradient(linear, left top, right top, from(#ad399c), to(#eb1750));
    background: linear-gradient(to right, #ad399c 0%, #eb1750 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ad399c', endColorstr='#eb1750', GradientType=1 ); }
    .btn-more i::before {
      content: "";
      width: 14px;
      height: 24px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 0;
      top: 0;
      margin: auto;
      background: url(../img/svg/arrow-r.svg) no-repeat; }
  .btn-more:hover {
    box-shadow: 0 18px 59px 0 rgba(0, 0, 0, 0.7); }

@keyframes pulse {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  50% {
    transform: scale(0.9); }
  70% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

a {
  text-decoration: none;
  transition: color .35s linear; }
  a:hover {
    text-decoration: none; }

.btn {
  font-size: 1.66667rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  min-width: 240px;
  line-height: 62px;
  font-weight: 300;
  display: inline-block;
  transition: all .4s linear;
  border-radius: 10px;
  background: #e92c5e;
  background: -moz-linear-gradient(left, #e92c5e 0%, #d006ae 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #e92c5e), color-stop(100%, #d006ae));
  background: -webkit-linear-gradient(left, #e92c5e 0%, #d006ae 100%);
  background: -o-linear-gradient(left, #e92c5e 0%, #d006ae 100%);
  background: -ms-linear-gradient(left, #e92c5e 0%, #d006ae 100%);
  background: linear-gradient(to right, #e92c5e 0%, #d006ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e92c5e', endColorstr='#d006ae', GradientType=1 );
  box-shadow: 0 18px 59px 0 rgba(0, 0, 0, 0.4); }
  .btn:hover {
    box-shadow: 0 18px 59px 0 rgba(0, 0, 0, 0.7); }

@media (max-width: 767px) {
  .btn {
    line-height: 50px;
    min-width: 200px; } }

@media (max-width: 639px) {
  .btn {
    min-width: 170px; } }

@media (max-width: 479px) {
  .btn {
    font-size: 16px;
    min-width: 130px; } }

.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100%;
  padding: 25px 0; }
  .header .row-sb {
    padding: 0 30px;
    border-radius: 10px;
    position: relative;
    background: #f53a6c;
    background: -moz-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, #f53a6c), color-stop(100%, #d006ae));
    background: -webkit-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: -o-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: -ms-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: linear-gradient(to right, #f53a6c 0%, #d006ae 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
    box-shadow: 0 8px 49px 0 rgba(0, 0, 0, 0.6); }
  .header ul {
    display: flex; }
    .header ul li {
      position: relative;
      align-items: center;
      display: flex;
      margin: 0 15px;
      padding: 0 5px; }
      .header ul li:after {
        transition: width .4s linear;
        content: "";
        width: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        margin: auto;
        right: 0;
        background: #fff;
        height: 3px; }
      .header ul li:hover:after {
        width: 100%; }
    .header ul a {
      color: #fff; }
  .header .logo {
    padding: 12px 0; }
    .header .logo:after {
      content: "";
      width: 37px;
      height: 87px;
      background: url(../img/shadow-l.png) no-repeat;
      position: absolute;
      top: 5px;
      left: 214px; }
  .header-language {
    width: 50px;
    font-weight: 300;
    font-size: 18px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    margin-left: 50px;
    justify-content: center;
    height: 100%;
    position: relative; }
    .header-language::before {
      content: "";
      width: 37px;
      height: 87px;
      background: url(../img/shadow-r.png) no-repeat;
      position: absolute;
      top: 5px;
      right: 53px; }
    .header-language:hover:after {
      transform: rotate(0deg);
      top: 42px; }
    .header-language:hover ul {
      opacity: 1;
      pointer-events: auto; }
    .header-language ul {
      position: absolute;
      flex-direction: column;
      transition: all .4s linear;
      padding: 25px 0 0;
      top: 35px;
      opacity: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      background: rgba(255, 255, 255, 0);
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 59%, rgba(255, 255, 255, 0.5) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(59%, rgba(255, 255, 255, 0.2)), color-stop(100%, rgba(255, 255, 255, 0.5)));
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 59%, rgba(255, 255, 255, 0.5) 100%);
      background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 59%, rgba(255, 255, 255, 0.5) 100%);
      background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 59%, rgba(255, 255, 255, 0.5) 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 59%, rgba(255, 255, 255, 0.5) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 ); }
      .header-language ul li {
        margin: 3px 0;
        text-align: center; }
        .header-language ul li:after {
          display: none; }
      .header-language ul a {
        display: block;
        width: 100%;
        color: #000; }
    .header-language:after {
      content: "";
      width: 12px;
      height: 10px;
      background: url(../img/svg/accordion-arrow-w.svg) no-repeat;
      position: absolute;
      transition: all .4s linear;
      right: -3px;
      transform: rotate(180deg);
      top: 37px; }
    .header-language li {
      border-top: 0;
      padding-top: 0px;
      padding-bottom: 3px;
      text-align: center; }
    .header-language a {
      color: #000; }
      .header-language a:hover {
        color: #fff; }
    .header-language__active {
      color: #fff; }

.header-nav {
  font-weight: 500;
  font-size: 1.11111rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.btn-menu {
  width: 30px;
  height: 30px;
  z-index: 9;
  pointer-events: auto;
  position: relative;
  transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
  cursor: pointer;
  display: none; }
  .btn-menu .line {
    position: absolute;
    right: 0;
    top: 50%;
    background: #fff;
    width: 30px;
    height: 4px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
  .btn-menu .line:after {
    content: "";
    width: 30px;
    height: 4px;
    background: #fff;
    transition: all .3s linear;
    position: absolute;
    right: 0;
    top: 9px; }
  .btn-menu .line:before {
    content: "";
    background: #fff;
    width: 30px;
    height: 4px;
    transition: all .3s linear;
    position: absolute;
    right: 0;
    top: -9px; }

.btn-menu.show .line {
  height: 0; }

.btn-menu.show .line:before {
  transform: rotate(45deg);
  background: #fff;
  top: -2px;
  width: 26px; }

.btn-menu.show .line:after {
  transform: rotate(-45deg);
  background: #fff;
  top: -2px;
  width: 26px; }

@media (max-width: 1260px) {
  .header {
    padding: 15px 0; }
  .header .logo img {
    width: 140px; }
  .header .row-sb {
    padding: 0 15px; }
  .header-language:after {
    top: 28px; }
  .header-language:hover:after {
    top: 32px; }
  .header .logo:after,
  .header-language::before {
    display: none; }
  .header-language {
    margin-left: 0; } }

@media (max-width: 959px) {
  .header .logo img {
    width: 90px; }
  .header ul li {
    margin: 0 6px; }
  .header-language:after {
    top: 23px; }
  .header-language:hover:after {
    top: 27px; } }

@media (max-width: 800px) {
  .header .row-sb {
    display: flex;
    align-items: center; }
  .header-nav {
    position: fixed;
    max-height: 0;
    border-top: 1px solid transparent;
    transition: all .4s linear;
    overflow: hidden;
    background: #f53a6c;
    background: -moz-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, #f53a6c), color-stop(100%, #d006ae));
    background: -webkit-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: -o-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: -ms-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: linear-gradient(to right, #f53a6c 0%, #d006ae 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
    left: 15px;
    right: 15px;
    border-radius: 0 0 15px 15px;
    top: 72px;
    display: flex;
    flex-direction: column; }
    .header-nav li {
      line-height: 46px;
      text-transform: uppercase;
      text-align: center;
      font-size: 18px;
      opacity: 0;
      transition: all .4s linear;
      justify-content: center; }
      .header-nav li:hover:after {
        display: none; }
    .header-nav ul {
      flex-direction: column; }
    .header-nav.show {
      max-height: 600px;
      border-top: 1px solid #fff; }
      .header-nav.show li {
        transition-delay: 0.5s;
        opacity: 1; }
      .header-nav.show .header-language__active {
        opacity: 1;
        transition-delay: 0.5s; }
  .btn-menu {
    display: block; }
  .header {
    padding: 15px 0; }
  .header-language {
    width: 100%;
    display: flex; }
    .header-language__active {
      transition: all .4s linear;
      margin: 0 10px;
      opacity: 0; }
    .header-language:after {
      display: none; }
    .header-language:hover ul {
      background: none; }
    .header-language ul {
      position: static;
      margin: 0;
      flex-direction: row;
      opacity: 1;
      padding: 0;
      background: none; } }

.header-nav.show .btn-prize {
  opacity: 1;
  transition-delay: 0.5s; }

.header-nav.show .btn-box {
  opacity: 1;
  transition-delay: 0.5s; }

.btn-prize {
  display: inline-block;
  padding-right: 38px;
  position: relative;
  min-width: 185px;
  border-radius: 10px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  text-align: right;
  line-height: 40px;
  transition: all .4s linear;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.24); }
  .btn-prize:hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.6); }
  @media (max-width: 1100px) {
    .btn-prize {
      padding-right: 10px;
      min-width: 105px;
      font-size: 16px; } }
  @media (max-width: 800px) {
    .btn-prize {
      opacity: 0; } }
  .btn-prize:after {
    content: "";
    width: 40px;
    height: 26px;
    background: url(../img/svg/prize.svg) no-repeat;
    position: absolute;
    left: 11px;
    top: 0;
    bottom: 0;
    margin: auto; }
    @media (max-width: 1100px) {
      .btn-prize:after {
        width: 34px;
        height: 20px; } }

.btn-box {
  display: inline-block;
  padding-right: 20px;
  position: relative;
  text-align: right;
  min-width: 105px;
  border-radius: 10px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 20px;
  margin-left: 22px;
  transition: all .4s linear;
  font-weight: 300;
  line-height: 40px;
  border-left: 0;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.24); }
  .btn-box:hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.6); }
  @media (max-width: 1100px) {
    .btn-box {
      padding-right: 10px;
      min-width: 80px;
      font-size: 16px; } }
  @media (max-width: 800px) {
    .btn-box {
      opacity: 0; } }
  .btn-box:after {
    content: "";
    width: 40px;
    height: 40px;
    background: url(../img/svg/box.svg) no-repeat;
    position: absolute;
    left: -11px;
    top: 0;
    bottom: 0;
    margin: auto; }

.global-banner {
  z-index: 2; }
  .global-banner .slick-list {
    overflow: visible;
    padding-bottom: 32px; }
  .global-banner__item {
    height: 700px;
    background-size: cover;
    display: flex;
    background-position: center; }
    .global-banner__item .container {
      align-items: flex-end;
      height: 100%;
      display: flex; }
    .global-banner__item:nth-child(1) .global-banner__box {
      background: url(../img/blur1.jpg) no-repeat; }
  .global-banner .slick-slide.slick-current .global-banner__box {
    transform: translateX(0);
    opacity: 1; }
  .global-banner__box {
    border-radius: 10px;
    padding: 35px 35px 68px;
    position: relative;
    bottom: -20px;
    max-width: 580px;
    transform: translateX(-100px);
    opacity: 0;
    display: flex;
    transition: all .4s linear;
    transition-delay: 0.5s;
    justify-content: space-between;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.45);
    border: 1px solid #ddd;
    flex-wrap: wrap; }
    .global-banner__box > * {
      position: relative;
      z-index: 2; }
    .global-banner__box .title {
      line-height: 1.2;
      flex: 0 0 100%;
      display: block;
      font-weight: 300;
      font-size: 2.22222rem;
      margin-bottom: 30px; }
  .global-banner .slick-slide.slick-current .global-banner__box {
    transform: translateX(0);
    opacity: 1; }

.marker-map {
  width: 84px;
  height: 109px;
  position: absolute;
  top: 49px;
  left: 577px; }
  .marker-map:after {
    -webkit-animation: pulse 1s infinite linear;
    animation: pulse 1s infinite linear;
    content: '';
    position: absolute;
    left: 0;
    bottom: -9px;
    background-color: #fff;
    border-radius: 50%;
    height: 28px;
    margin: auto;
    right: 0;
    width: 28px;
    z-index: 1;
    -webkit-box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.7);
    opacity: 0.5; }
  .marker-map img {
    position: relative;
    z-index: 2;
    animation: marker 1.8s ease-out infinite; }

@keyframes marker {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(-8px); }
  80% {
    transform: translateY(4px); }
  100% {
    transform: translateY(0); } }

.about-section {
  position: relative; }
  .about-section.animated .about-list li {
    transform: translateX(0);
    opacity: 1; }

.about-map {
  width: 64%;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  background: url(../img/bg-map2.png) no-repeat;
  background-position: left top; }

.about-list {
  font-size: 1.33333rem;
  font-weight: 300;
  max-width: 470px;
  padding-top: 6.11111rem;
  padding-bottom: 50px; }
  .about-list img {
    max-width: 80px;
    margin-right: 2.22222rem;
    max-height: 100px; }
  .about-list li {
    display: flex;
    line-height: 1.2;
    align-items: center;
    margin-bottom: 2.77778rem;
    transition: all .5s linear;
    transition-delay: 0.3s;
    transform: translateX(-150px);
    opacity: 0; }
    .about-list li:nth-child(2) {
      transition-delay: 0.6s; }
    .about-list li:nth-child(3) {
      transition-delay: 0.9s; }
    .about-list li:nth-child(4) {
      transition-delay: 1.2s; }

.download-section {
  margin: 6.66667rem 0 8.88889rem; }
  @media (max-width: 767px) {
    .download-section {
      margin: 30px 0 60px; } }
  .download-section .container {
    max-width: 1130px; }

.download-table .row-th {
  line-height: 30px;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 2px solid #dedede; }
  @media (max-width: 767px) {
    .download-table .row-th {
      display: none; } }

.download-table .col {
  padding: 0 30px;
  text-align: center; }
  @media (max-width: 1023px) {
    .download-table .col {
      padding: 0 15px; } }
  .download-table .col-name {
    max-width: 165px;
    flex: 0 0 15%; }
    .download-table .col-name img {
      display: block;
      margin: 0 auto; }
    .download-table .col-name .title {
      display: block;
      text-align: center;
      font-size: 20px;
      font-weight: 300; }
  .download-table .col-text {
    flex: 0 0 63%;
    max-width: 540px;
    font-weight: 300; }
    @media (max-width: 1023px) {
      .download-table .col-text {
        max-width: 350px; } }
    @media (max-width: 767px) {
      .download-table .col-text {
        flex: 0 0 100%;
        max-width: 100%;
        padding-top: 20px; } }
  .download-table .col-btn {
    flex: 0 0 22%;
    max-width: 245px; }
    @media (max-width: 767px) {
      .download-table .col-btn {
        flex: 0 0 100%;
        margin-top: 25px; } }

.download-table .row-td {
  padding: 25px 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #dedede; }
  @media (max-width: 767px) {
    .download-table .row-td {
      flex-direction: column;
      justify-content: center; } }
  .download-table .row-td .col-btn {
    padding: 0; }
  .download-table .row-td .col-text {
    font-size: 18px;
    text-align: left; }
    @media (max-width: 1023px) {
      .download-table .row-td .col-text {
        font-size: 16px; } }
  .download-table .row-td .col-name {
    padding: 0; }

.btn-download {
  font-size: 1.38889rem;
  border: 0;
  cursor: pointer;
  overflow: hidden;
  position: relative; }
  .btn-download:after {
    content: "";
    background: #e92c5e;
    background: -webkit-gradient(left top, right top, color-stop(0%, #e92c5e), color-stop(100%, #d006ae));
    background: -webkit-linear-gradient(left, #e92c5e 0%, #d006ae 100%);
    background: -o-linear-gradient(left, #e92c5e 0%, #d006ae 100%);
    background: -webkit-gradient(linear, left top, right top, from(#e92c5e), to(#d006ae));
    background: linear-gradient(to right, #e92c5e 0%, #d006ae 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e92c5e', endColorstr='#d006ae', GradientType=1 );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .4s linear;
    z-index: 1; }
  .btn-download::before {
    content: "";
    border-radius: 8px;
    top: 2px;
    left: 2px;
    right: 2px;
    background: #fff;
    opacity: 0;
    transition: all .4s linear;
    bottom: 2px;
    z-index: 2;
    position: absolute; }
  .btn-download span {
    position: relative;
    z-index: 3; }
  .btn-download:hover {
    background: #fff;
    color: #000; }
    .btn-download:hover::before {
      opacity: 1; }
  @media (max-width: 1100px) {
    .btn-download {
      min-width: 200px; } }
  @media (max-width: 479px) {
    .btn-download {
      font-size: 16px;
      padding: 0 15px; } }

.model-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  z-index: 99;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); }
  .model-window .error {
    text-align: center;
    color: #e91650;
    font-size: 16px; }
  .model-window .btn {
    margin: 0 auto 2.22222rem;
    display: table; }
  .model-window .popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px 60px;
    background: #fff;
    border-radius: 15px;
    width: 520px;
    height: 350px;
    margin: auto;
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.17); }
    @media (max-width: 600px) {
      .model-window .popup {
        width: 300px;
        height: 290px;
        padding: 20px 10px; } }
    .model-window .popup .item-input {
      margin-bottom: 2.5rem; }
      .model-window .popup .item-input input {
        border: 1px solid #000;
        line-height: 46px;
        height: 46px;
        width: 100%;
        text-align: center;
        border-width: 0 0 1px; }
    .model-window .popup .title {
      display: block;
      text-align: center;
      color: #2b3942;
      font-weight: 400;
      margin-bottom: 1.38889rem;
      font-size: 1.38889rem;
      text-transform: uppercase; }

@media (max-width: 1700px) {
  .about-map {
    background: url(../img/bg-map.png) no-repeat; } }

@media (max-width: 1260px) {
  .about-list img {
    max-width: 50px;
    max-height: 80px; }
  .about-map {
    background-size: cover; }
  .global-banner__box {
    padding-bottom: 35px; }
  .marker-map {
    top: 0px;
    left: 392px; } }

@media (max-width: 1023px) {
  .global-banner__item {
    height: 560px; }
  .marker-map {
    top: -3px;
    left: 359px; } }

@media (max-width: 767px) {
  .btn-more {
    padding: 0 10px 0 40px;
    line-height: 50px;
    min-width: 200px; }
  .global-banner__box {
    max-width: 460px; }
  .about-list {
    padding-bottom: 0px; }
  .about-map {
    display: none; } }

@media (max-width: 639px) {
  .global-banner__box {
    padding: 20px 20px 35px; }
  .global-banner__box .btn {
    margin-right: 0; }
  .btn-more {
    min-width: 190px; }
  .product-slider .slick-list,
  .provider-slider .slick-list {
    box-sizing: content-box; } }

@media (max-width: 479px) {
  .btn-more {
    min-width: 160px;
    font-size: 16px; }
  .global-banner__box .title {
    font-size: 24px; } }

@media (max-width: 374px) {
  .global-banner__box .btn-more {
    margin-bottom: 15px; }
  .global-banner__box .btn-more,
  .global-banner__box .btn {
    min-width: 100%; } }

.product-bg {
  padding-top: 3.88889rem;
  background: url(../img/bg-product.png) no-repeat;
  background-position: left top;
  display: flex;
  justify-content: flex-end; }

.product-section.animated .product-slider {
  transform: translateX(0);
  opacity: 1; }

.product-slider {
  max-width: 1340px;
  transform: translateX(500px);
  transition: all .6s linear;
  transition-delay: 0.2s;
  opacity: 0; }
  .product-slider .slick-arrow {
    position: absolute; }
  .product-slider .slick-track {
    display: flex; }
  .product-slider .slick-slide {
    height: auto; }
    .product-slider .slick-slide.slick-current + .slick-slide + .slick-slide .fig-circle {
      display: none; }
    .product-slider .slick-slide.slick-current .fig-circle span {
      background: #ad399c;
      background: -moz-linear-gradient(left, #ad399c 0%, #eb1750 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, #ad399c), color-stop(100%, #eb1750));
      background: -webkit-linear-gradient(left, #ad399c 0%, #eb1750 100%);
      background: -o-linear-gradient(left, #ad399c 0%, #eb1750 100%);
      background: -ms-linear-gradient(left, #ad399c 0%, #eb1750 100%);
      background: linear-gradient(to right, #ad399c 0%, #eb1750 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ad399c', endColorstr='#eb1750', GradientType=1 ); }
      .product-slider .slick-slide.slick-current .fig-circle span::before {
        transform: rotate(180deg);
        left: -4px; }
    .product-slider .slick-slide.slick-current .fig-circle:after {
      animation: pulse 2s infinite linear;
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      top: 0;
      background-color: #fff;
      border-radius: 50%;
      margin: auto 0 auto -54px;
      height: 108px;
      width: 108px;
      -webkit-box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.7);
      box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.7);
      z-index: -1;
      opacity: 0.5; }
    .product-slider .slick-slide.slick-current .item {
      background: #f53a6c;
      border-radius: 15px;
      background: -moz-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, #f53a6c), color-stop(100%, #d006ae));
      background: -webkit-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
      background: -o-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
      background: -ms-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
      background: linear-gradient(to right, #f53a6c 0%, #d006ae 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 ); }
    .product-slider .slick-slide > div {
      height: 100%; }
  .product-slider .slick-list {
    padding: 20px 300px 100px 0; }
  .product-slider .item {
    padding: 2px;
    position: relative;
    border-radius: 15px;
    height: 100%;
    margin: 0 15px;
    max-width: 485px;
    box-shadow: 0 0 46px 0 rgba(0, 0, 0, 0.23);
    font-size: 1.33333rem;
    text-align: center;
    font-weight: 300;
    background: #fff; }
    .product-slider .item img {
      margin: 0 auto 2.77778rem; }
    .product-slider .item .box {
      height: 100%;
      border-radius: 15px;
      padding: 30px 50px 50px;
      line-height: 1.2;
      overflow: hidden;
      background: #fff; }

.product-section .global-title {
  margin-bottom: 3.05556rem; }

@media (max-width: 1400px) {
  .product-slider .item img {
    width: 100%; }
  .product-bg {
    padding-top: 8.88889rem; } }

@media (max-width: 1330px) {
  .product-slider .item {
    max-width: 385px; }
  .product-slider .slick-list {
    padding-right: 230px; }
  .product-bg {
    padding-bottom: 2.5rem;
    padding-top: 2rem; }
  .product-slider {
    max-width: 1040px; }
  .product-section .global-title {
    margin-bottom: 2rem; } }

@media (max-width: 1260px) {
  .product-slider .slick-list {
    padding: 20px 0 100px; }
  .product-bg {
    padding: 2.5rem 15px; }
  .product-slider {
    max-width: 100%; }
  .product-slider .slick-slide {
    display: flex;
    justify-content: center; } }

.partners-tabs {
  transform: translateY(300px);
  transition: all .5s linear;
  opacity: 0; }

.partners-section {
  margin-bottom: 35px; }
  .partners-section.animated .partners-tabs {
    transform: translateY(0);
    opacity: 1; }

.partners-section {
  margin-bottom: 35px; }

.partners-items {
  padding: 22px 40px;
  font-size: 1.33333rem;
  font-weight: 300;
  line-height: 1.3;
  background: #fff;
  position: relative;
  box-shadow: 0 0 46px 0 rgba(0, 0, 0, 0.23);
  background: #fff;
  border-radius: 0 0 15px 15px; }
  .partners-items .text {
    flex: 0 0 66%;
    padding-right: 30px;
    position: relative; }
    .partners-items .text:after {
      content: '';
      width: 2px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(110, 110, 110, 0);
      background: -moz-linear-gradient(top, rgba(110, 110, 110, 0) 0%, rgba(110, 110, 110, 0) 0%, #6e6e6e 21%, #6e6e6e 80%, rgba(110, 110, 110, 0) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(110, 110, 110, 0)), color-stop(0%, rgba(110, 110, 110, 0)), color-stop(21%, #6e6e6e), color-stop(80%, #6e6e6e), color-stop(100%, rgba(110, 110, 110, 0)));
      background: -webkit-linear-gradient(top, rgba(110, 110, 110, 0) 0%, rgba(110, 110, 110, 0) 0%, #6e6e6e 21%, #6e6e6e 80%, rgba(110, 110, 110, 0) 100%);
      background: -o-linear-gradient(top, rgba(110, 110, 110, 0) 0%, rgba(110, 110, 110, 0) 0%, #6e6e6e 21%, #6e6e6e 80%, rgba(110, 110, 110, 0) 100%);
      background: -ms-linear-gradient(top, rgba(110, 110, 110, 0) 0%, rgba(110, 110, 110, 0) 0%, #6e6e6e 21%, #6e6e6e 80%, rgba(110, 110, 110, 0) 100%);
      background: linear-gradient(to bottom, rgba(110, 110, 110, 0) 0%, rgba(110, 110, 110, 0) 0%, #6e6e6e 21%, #6e6e6e 80%, rgba(110, 110, 110, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6e6e6e', endColorstr='#6e6e6e', GradientType=0 ); }
  .partners-items .partners-fig {
    flex: 0 0 34%;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    font-size: 1.33333rem;
    font-weight: 300;
    text-transform: uppercase; }
    .partners-items .partners-fig li {
      position: relative;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 30px;
      text-align: center;
      transition: all .4s linear;
      flex-direction: column;
      color: #000; }
      .partners-items .partners-fig li:hover {
        color: #ed1651; }
        .partners-items .partners-fig li:hover .circle {
          opacity: 1; }
        .partners-items .partners-fig li:hover img {
          -webkit-filter: grayscale(0%);
          -moz-filter: grayscale(0%);
          -ms-filter: grayscale(0%);
          -o-filter: grayscale(0%);
          filter: grayscale(0%);
          filter: none;
          /* Firefox 4+ */
          filter: rgba(128, 128, 128, 0); }
    .partners-items .partners-fig img {
      cursor: pointer;
      transition: all .4s linear;
      display: block;
      max-height: 70px;
      max-width: 80px;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      -webkit-filter: gray;
      margin: 0 auto 30px; }

.partners-title {
  position: relative;
  z-index: 2; }
  .partners-title li {
    flex: 0 0 25%;
    max-width: 25%;
    text-align: center;
    cursor: pointer;
    display: flex;
    position: relative;
    padding: 5px 5px 10px;
    justify-content: center;
    border-radius: 15px 15px 0 0; }
    .partners-title li img {
      position: relative;
      z-index: 1;
      transition: all .4s linear;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      filter: gray;
      display: block; }
    .partners-title li.active {
      box-shadow: 0 0 46px 0 rgba(0, 0, 0, 0.23); }
      .partners-title li.active img {
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        -ms-filter: grayscale(0%);
        -o-filter: grayscale(0%);
        filter: grayscale(0%);
        filter: none;
        /* Firefox 4+ */
        filter: rgba(128, 128, 128, 0); }
      .partners-title li.active:after {
        content: "";
        width: 100%;
        background: #fff;
        position: absolute;
        bottom: -25px;
        height: 50px;
        left: 0; }

.circle {
  width: 55px;
  top: 15px;
  height: 55px;
  right: -20px;
  opacity: 0;
  transition: all .4s linear;
  border-radius: 50%;
  border: 3px solid #fff;
  background: -moz-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #f53a6c), color-stop(100%, #d006ae));
  background: -webkit-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
  background: -o-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
  background: -ms-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
  background: linear-gradient(to right, #f53a6c 0%, #d006ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
  color: #fff;
  text-align: center;
  line-height: 52px;
  font-size: 20px;
  position: absolute; }

.provider-section.animated .provider-slider {
  transform: translateX(0);
  opacity: 1; }

.provider-section {
  margin-bottom: 11.11111rem; }

.provider-bg {
  background: url(../img/bg-provider.png) no-repeat;
  display: flex;
  background-position: right top;
  padding: 65px 70px 0 0;
  justify-content: flex-end; }

.provider-slider {
  max-width: 1580px;
  display: flex;
  transform: translateX(-800px);
  transition: all .6s linear;
  width: 100%;
  transition-delay: 0.2s;
  opacity: 0;
  padding: 20px 0 80px 0; }
  .provider-slider .slick-arrow {
    position: absolute; }
  .provider-slider .slick-track {
    display: flex; }
  .provider-slider-bg {
    display: flex;
    justify-content: flex-end; }
  .provider-slider .slick-slide, .provider-slider .item {
    height: auto; }
    .provider-slider .slick-slide.slick-current, .provider-slider .item.slick-current {
      background: #f53a6c;
      border-radius: 15px;
      background: -moz-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, #f53a6c), color-stop(100%, #d006ae));
      background: -webkit-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
      background: -o-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
      background: -ms-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
      background: linear-gradient(to right, #f53a6c 0%, #d006ae 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 ); }
      .provider-slider .slick-slide.slick-current .fig-circle span, .provider-slider .item.slick-current .fig-circle span {
        background: #ad399c;
        background: -moz-linear-gradient(left, #ad399c 0%, #eb1750 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, #ad399c), color-stop(100%, #eb1750));
        background: -webkit-linear-gradient(left, #ad399c 0%, #eb1750 100%);
        background: -o-linear-gradient(left, #ad399c 0%, #eb1750 100%);
        background: -ms-linear-gradient(left, #ad399c 0%, #eb1750 100%);
        background: linear-gradient(to right, #ad399c 0%, #eb1750 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ad399c', endColorstr='#eb1750', GradientType=1 ); }
        .provider-slider .slick-slide.slick-current .fig-circle span::before, .provider-slider .item.slick-current .fig-circle span::before {
          transform: rotate(180deg);
          left: -4px; }
      .provider-slider .slick-slide.slick-current .fig-circle:after, .provider-slider .item.slick-current .fig-circle:after {
        animation: pulse 2s infinite linear;
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        top: 0;
        background-color: #fff;
        border-radius: 50%;
        margin: auto 0 auto -54px;
        height: 108px;
        width: 108px;
        -webkit-box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.7);
        box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.7);
        z-index: -1;
        opacity: 0.5; }
    .provider-slider .slick-slide > div, .provider-slider .item > div {
      height: 100%; }
  .provider-slider .item {
    padding: 2px;
    border-radius: 15px;
    height: 100%;
    margin: 0 15px;
    position: relative;
    max-width: 485px;
    box-shadow: 0 0 46px 0 rgba(0, 0, 0, 0.23);
    font-size: 1.33333rem;
    text-align: center;
    font-weight: 300;
    background: #fff; }
    .provider-slider .item img {
      margin: 0 auto 2.77778rem;
      max-height: 160px; }
    .provider-slider .item .box {
      height: 100%;
      border-radius: 15px;
      padding: 30px 30px 60px;
      line-height: 1.1;
      overflow: hidden;
      background: #fff; }

@media (max-width: 1600px) {
  .provider-section {
    margin-bottom: 8rem; }
  .provider-slider {
    max-width: 1300px;
    width: 100%; }
  .provider-slider .item {
    max-width: 385px; }
  .provider-slider .slick-list {
    padding: 35px 0 80px 0; } }

@media (max-width: 1330px) {
  .provider-section {
    margin-bottom: 6rem; }
  .partners-items .partners-fig li {
    padding-bottom: 10px; }
  .provider-bg {
    padding: 65px 15px;
    justify-content: center; }
  .provider-slider .slick-slide > div {
    display: flex;
    justify-content: center; }
  .provider-slider {
    justify-content: center; } }

@media (max-width: 1260px) {
  .partners-title li img {
    width: 100%;
    max-width: 160px;
    height: 100%; }
  .provider-bg {
    background: #f53a6c;
    background: -moz-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, #f53a6c), color-stop(100%, #d006ae));
    background: -webkit-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: -o-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: -ms-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: linear-gradient(to right, #f53a6c 0%, #d006ae 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 ); } }

@media (max-width: 1200px) {
  .provider-bg {
    padding: 20px 15px; }
  .partners-items .partners-fig img {
    max-width: 50px; }
  .partners-items .partners-fig {
    font-size: 16px; } }

@media (max-width: 1023px) {
  .product-slider .slick-list {
    padding: 20px 0 60px; }
  .product-section .global-title {
    margin-bottom: 0; }
  .partners-title li.active:after {
    content: "";
    width: 100%;
    background: #fff;
    position: absolute;
    bottom: -22px;
    height: 40px;
    left: 0; } }

@media (max-width: 959px) {
  .product-slider .item {
    max-width: 300px; }
    .product-slider .item .box {
      padding: 30px 40px 50px; }
  .provider-slider .item {
    max-width: 300px; }
  .partners-title li img {
    width: 100%;
    max-width: 110px;
    height: 100%; }
  .partners-items .partners-fig {
    display: none; }
  .partners-items .text {
    flex: 0 0 100%;
    max-width: 100%; }
    .partners-items .text:after {
      display: none; }
  .provider-slider .item img {
    max-height: 130px; }
  .btn-more i {
    width: 35px;
    height: 35px; }
    .btn-more i:before {
      width: 10px;
      height: 16px; } }

@media (max-width: 767px) {
  .product-slider .item {
    max-width: 260px; }
    .product-slider .item .box {
      padding: 25px 20px 40px; }
  .partners-items {
    padding: 20px; }
  .provider-slider .item img {
    max-height: 90px; }
  .provider-slider .item {
    max-width: 260px; }
    .provider-slider .item .box {
      padding: 25px 20px 40px; } }

@media (max-width: 639px) {
  .partners-title li img {
    max-width: 90px; }
  .slick-arrow {
    border: 0;
    background: none;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
    width: 40px;
    height: 40px;
    cursor: pointer;
    padding: 0;
    border-radius: 50%;
    font-size: 0;
    background: #f53a6c;
    background: -moz-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, #f53a6c), color-stop(100%, #d006ae));
    background: -webkit-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: -o-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: -ms-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
    background: linear-gradient(to right, #f53a6c 0%, #d006ae 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
    left: 0;
    border: 1px solid #fff; }
    .slick-arrow.slick-prev:after {
      transform: rotate(180deg); }
    .slick-arrow:after {
      content: "";
      width: 11px;
      height: 20px;
      background: url(../img/svg/arrow-r.svg) no-repeat;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0; }
    .slick-arrow.slick-next {
      right: 0;
      left: auto; } }

@media (max-width: 400px) {
  .product-slider .item,
  .provider-slider .item {
    max-width: 240px; }
  .partners-title li img {
    max-width: 80px; } }

@media (max-width: 374px) {
  .product-slider .item,
  .provider-slider .item {
    max-width: 210px; }
  .slick-arrow {
    width: 30px;
    height: 30px; }
  .product-slider .item .box {
    padding: 25px 10px 40px; }
  .provider-slider .item .box {
    padding: 25px 10px 40px; } }

.contact-section {
  margin-bottom: 5.55556rem;
  padding: 0 15px; }
  .contact-section.animated .container {
    transform: translateY(0px);
    opacity: 1; }
  .contact-section .container {
    padding: 4.16667rem 3.05556rem 4.16667rem 3.88889rem;
    border-radius: 15px;
    box-shadow: 0 0 59px 0 rgba(0, 0, 0, 0.29);
    max-width: 1450px;
    transform: translateY(300px);
    transition: all .5s linear;
    opacity: 0; }
    .contact-section .container .row {
      align-items: center; }
  .contact-section .map {
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 9px 59px 0 rgba(0, 0, 0, 0.29); }
    .contact-section .map .marker {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #ed1651;
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      z-index: 2;
      animation: pulse-s 2s infinite;
      -webkit-box-shadow: 0 0 0 rgba(237, 22, 81, 0.5);
      box-shadow: 0 0 0 rgba(237, 22, 81, 0.5); }
    .contact-section .map img {
      display: block; }

.contact-info {
  padding: 0 3.88889rem;
  font-weight: 300;
  font-size: 1.33333rem;
  line-height: 28px; }
  .contact-info p, .contact-info address {
    margin-bottom: 25px; }
  .contact-info a {
    display: table;
    color: #000; }
    .contact-info a:hover {
      color: #ed1651; }

.contact-menu {
  padding-right: 5.55556rem;
  position: relative;
  font-size: 1.33333rem;
  font-weight: 300; }
  .contact-menu:after {
    content: '';
    width: 2px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(110, 110, 110, 0);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(110, 110, 110, 0)), color-stop(0%, rgba(110, 110, 110, 0)), color-stop(21%, #6e6e6e), color-stop(80%, #6e6e6e), color-stop(100%, rgba(110, 110, 110, 0)));
    background: -webkit-linear-gradient(top, rgba(110, 110, 110, 0) 0%, rgba(110, 110, 110, 0) 0%, #6e6e6e 21%, #6e6e6e 80%, rgba(110, 110, 110, 0) 100%);
    background: -o-linear-gradient(top, rgba(110, 110, 110, 0) 0%, rgba(110, 110, 110, 0) 0%, #6e6e6e 21%, #6e6e6e 80%, rgba(110, 110, 110, 0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(110, 110, 110, 0)), color-stop(0%, rgba(110, 110, 110, 0)), color-stop(21%, #6e6e6e), color-stop(80%, #6e6e6e), to(rgba(110, 110, 110, 0)));
    background: linear-gradient(to bottom, rgba(110, 110, 110, 0) 0%, rgba(110, 110, 110, 0) 0%, #6e6e6e 21%, #6e6e6e 80%, rgba(110, 110, 110, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6e6e6e', endColorstr='#6e6e6e', GradientType=0 ); }
  .contact-menu li {
    line-height: 50px; }
  .contact-menu a {
    color: #000; }
    .contact-menu a:hover {
      color: #ed1651; }

@media (max-width: 1260px) {
  .contact-info {
    padding: 0 20px; }
  .contact-menu {
    padding-right: 30px; }
  .contact-section .container {
    padding: 3.16667rem 2.5556rem; } }

@media (max-width: 1023px) {
  .contact-section .map img {
    width: 100%; } }

@media (max-width: 959px) {
  .contact-section .row {
    flex-wrap: wrap; }
  .contact-section .map {
    flex: 0 0 100%;
    margin-top: 25px; }
  .contact-menu {
    flex: 0 0 30%; }
  .contact-info {
    padding: 0 40px; } }

@media (max-width: 639px) {
  .contact-menu {
    display: none; }
  .contact-info {
    padding: 0; } }

@media (max-width: 479px) {
  .contact-section .container {
    padding: 20px 15px; } }

@keyframes pulse-s {
  0% {
    box-shadow: 0 0 0 0 rgba(237, 22, 81, 0.7); }
  70% {
    box-shadow: 0 0 0 18px rgba(204, 169, 44, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.motivation-slider {
  border-radius: 10px;
  margin-top: 140px;
  background: #dadada;
  margin-bottom: 15px; }
  @media (max-width: 1280px) {
    .motivation-slider {
      margin-top: 140px; } }
  @media (max-width: 1260px) {
    .motivation-slider {
      margin-top: 110px; } }
  @media (max-width: 940px) {
    .motivation-slider {
      margin-top: 100px; } }
  @media (max-width: 540px) {
    .motivation-slider {
      margin: 90px -15px 15px;
      border-radius: 0; } }
  .motivation-slider .slick-dots {
    position: absolute;
    bottom: 32px;
    right: 33px;
    justify-content: center;
    display: flex;
    z-index: 4;
    width: 180px; }
    @media (max-width: 940px) {
      .motivation-slider .slick-dots {
        width: 108px;
        right: 33px; } }
    @media (max-width: 670px) {
      .motivation-slider .slick-dots {
        margin: 0 auto;
        right: 0;
        left: 0;
        bottom: 20px; } }
    .motivation-slider .slick-dots li {
      border: 2px solid #747474;
      border-radius: 50%;
      width: 12px;
      margin: 0 7px;
      height: 12px;
      cursor: pointer; }
      @media (max-width: 940px) {
        .motivation-slider .slick-dots li {
          margin: 0 3px; } }
      @media (max-width: 670px) {
        .motivation-slider .slick-dots li {
          border: 2px solid rgba(255, 255, 255, 0.6); } }
      .motivation-slider .slick-dots li.slick-active {
        background: #747474; }
        @media (max-width: 670px) {
          .motivation-slider .slick-dots li.slick-active {
            background: rgba(255, 255, 255, 0.6); } }
      .motivation-slider .slick-dots li button {
        border: 0;
        font-size: 0;
        background: none; }
  .motivation-slider_wrap {
    position: relative; }
  .motivation-slider_nav {
    position: absolute;
    width: 215px;
    z-index: 2;
    height: 28px;
    bottom: 22px;
    right: 10px; }
    @media (max-width: 940px) {
      .motivation-slider_nav {
        width: 155px; } }
  .motivation-slider_prev, .motivation-slider_next {
    position: absolute;
    width: 15px;
    cursor: pointer;
    height: 26px; }
  .motivation-slider_prev {
    left: 0;
    background: url(../img/svg/arrow-left_m.svg) no-repeat;
    border: 0; }
    @media (max-width: 670px) {
      .motivation-slider_prev {
        display: none; } }
    .motivation-slider_prev:after {
      display: none; }
    .motivation-slider_prev:hover {
      background: url(../img/svg/arrow-left_m_h.svg) no-repeat; }
  .motivation-slider_next {
    right: 0;
    background: url(../img/svg/arrow-right_m.svg) no-repeat;
    border: 0; }
    @media (max-width: 670px) {
      .motivation-slider_next {
        display: none; } }
    .motivation-slider_next:after {
      display: none; }
    .motivation-slider_next:hover {
      background: url(../img/svg/arrow-right_m_h.svg) no-repeat; }
  .motivation-slider .item {
    align-items: stretch;
    border-radius: 10px;
    padding-right: 220px;
    position: relative;
    min-height: 390px;
    overflow: hidden;
    display: flex !important;
    justify-content: space-between; }
    @media (max-width: 1280px) {
      .motivation-slider .item {
        padding-right: 80px;
        min-height: 360px; } }
    @media (max-width: 620px) {
      .motivation-slider .item {
        padding-right: 40px; } }
    @media (max-width: 540px) {
      .motivation-slider .item {
        border-radius: 0;
        padding-right: 15px; } }
    .motivation-slider .item .img {
      margin-top: 0;
      display: flex;
      align-items: flex-end; }
      .motivation-slider .item .img .mob-img {
        display: none; }
      @media (max-width: 860px) {
        .motivation-slider .item .img {
          position: relative;
          z-index: 2; } }
      @media (max-width: 540px) {
        .motivation-slider .item .img {
          align-items: flex-start;
          z-index: 0; }
          .motivation-slider .item .img.first-img {
            z-index: 3; }
            .motivation-slider .item .img.first-img .mob-img {
              display: block;
              max-width: 250px;
              position: relative;
              padding-top: 15px;
              margin-right: -85px;
              z-index: 3; }
          .motivation-slider .item .img.second-img {
            z-index: 3; }
            .motivation-slider .item .img.second-img .mob-img {
              display: block;
              max-width: 240px;
              position: relative;
              padding-top: 15px;
              margin-right: -55px;
              z-index: 3; }
          .motivation-slider .item .img img {
            display: none; }
          .motivation-slider .item .img .mob-img {
            display: block;
            padding-top: 15px; } }
      @media (max-width: 420px) {
        .motivation-slider .item .img .mob-img {
          max-width: 220px; } }
      @media (max-width: 400px) {
        .motivation-slider .item .img .mob-img {
          max-width: 180px; } }
      @media (max-width: 374px) {
        .motivation-slider .item .img .mob-img {
          max-width: 150px; } }
    .motivation-slider .item:after {
      content: "";
      width: 560px;
      background: #df0f68;
      position: absolute;
      top: 0;
      right: 8%;
      height: 100%;
      transform: skew(-40deg); }
      @media (max-width: 1280px) {
        .motivation-slider .item:after {
          width: 480px; } }
      @media (max-width: 1180px) {
        .motivation-slider .item:after {
          width: 400px; } }
      @media (max-width: 940px) {
        .motivation-slider .item:after {
          width: 330px;
          transform: skew(-37deg); } }
      @media (max-width: 670px) {
        .motivation-slider .item:after {
          right: 6%;
          transform: skew(-37deg); } }
      @media (max-width: 480px) {
        .motivation-slider .item:after {
          width: 250px;
          right: 13%; } }
      @media (max-width: 440px) {
        .motivation-slider .item:after {
          width: 250px;
          right: 5%; } }
      @media (max-width: 400px) {
        .motivation-slider .item:after {
          right: -1%;
          transform: skew(-30deg); } }
      @media (max-width: 374px) {
        .motivation-slider .item:after {
          width: 250px;
          right: 4%; } }
      @media (max-width: 355px) {
        .motivation-slider .item:after {
          width: 210px;
          right: 3%;
          transform: skew(-24deg); } }
    .motivation-slider .item img {
      display: block; }
      @media (max-width: 1280px) {
        .motivation-slider .item img {
          padding-top: 15px; } }
      @media (max-width: 1180px) {
        .motivation-slider .item img {
          max-width: 420px;
          max-height: 350px; } }
      @media (max-width: 940px) {
        .motivation-slider .item img {
          max-width: 340px;
          max-height: 300px; } }
  .motivation-slider .info {
    width: 380px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding-top: 90px;
    justify-content: center; }
    @media (max-width: 1280px) {
      .motivation-slider .info {
        width: 360px; } }
    @media (max-width: 940px) {
      .motivation-slider .info {
        padding-top: 30px;
        width: 260px; } }
    @media (max-width: 440px) {
      .motivation-slider .info {
        padding-top: 0; } }
    .motivation-slider .info .title {
      display: block;
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase; }
      @media (max-width: 1280px) {
        .motivation-slider .info .title {
          font-size: 26px; } }
      @media (max-width: 940px) {
        .motivation-slider .info .title {
          font-size: 22px; } }
      @media (max-width: 415px) {
        .motivation-slider .info .title {
          font-size: 20px;
          letter-spacing: -0.5px; } }
    .motivation-slider .info .text {
      font-size: 20px;
      color: #fff;
      font-weight: 300; }
      @media (max-width: 670px) {
        .motivation-slider .info .text {
          font-size: 14px; } }
    .motivation-slider .info .btn {
      border: 2px solid #fff;
      font-size: 24px;
      text-align: center;
      margin-top: 22px;
      line-height: 60px;
      width: 300px;
      background: #dc0f67; }
      @media (max-width: 1280px) {
        .motivation-slider .info .btn {
          width: 230px;
          font-size: 18px;
          line-height: 42px; } }
      @media (max-width: 540px) {
        .motivation-slider .info .btn {
          width: 180px;
          position: absolute;
          bottom: 45px;
          left: 0;
          right: 0;
          margin: 0 auto;
          font-size: 13px;
          line-height: 36px; } }

.motivation-target {
  padding: 25px 10px;
  box-shadow: 0 4px 27px 0 rgba(0, 0, 0, 0.27);
  border-radius: 10px;
  margin-bottom: 15px; }
  @media (max-width: 767px) {
    .motivation-target {
      margin-bottom: 40px; } }
  .motivation-target .download {
    font-size: 20px;
    text-align: center;
    color: #000;
    line-height: 50px;
    margin: 0 auto;
    display: table;
    position: relative;
    min-width: 360px;
    background: #fff;
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.24);
    font-weight: 400;
    border: 2px solid #dc0f67; }
    .motivation-target .download:after {
      content: "";
      width: 15px;
      position: absolute;
      right: 6%;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 26px;
      transform: rotate(90deg);
      background: url(../img/svg/arrow-right_m_h.svg) no-repeat; }
      @media (max-width: 767px) {
        .motivation-target .download:after {
          height: 18px;
          width: 8px; } }
    .motivation-target .download:hover {
      box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.4); }
    .motivation-target .download span {
      color: #ba096e;
      display: inline-block;
      margin-left: 10px; }
    @media (max-width: 767px) {
      .motivation-target .download {
        font-size: 16px;
        min-width: 280px;
        letter-spacing: -0.5px;
        line-height: 44px; }
        .motivation-target .download span {
          margin-left: 0; } }
  .motivation-target ul {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-evenly;
    position: relative; }
    @media (max-width: 767px) {
      .motivation-target ul {
        flex-wrap: wrap;
        margin-bottom: 0; }
        .motivation-target ul:after {
          content: "";
          width: 100%;
          height: 1px;
          border-bottom: 1px dashed #d5d5d5;
          position: absolute;
          top: -35px;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto; }
        .motivation-target ul:before {
          content: "";
          width: 1px;
          border-left: 1px dashed #d5d5d5;
          position: absolute;
          top: 0px;
          left: 0;
          bottom: 20px;
          right: 0;
          margin: auto; } }
  .motivation-target li {
    flex: 0 0 25%;
    max-width: 230px;
    text-align: center;
    font-size: 13px;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .motivation-target li {
        flex: 0 0 50%;
        max-width: 50%; }
        .motivation-target li .box {
          margin-bottom: 35px; } }
    @media (max-width: 440px) {
      .motivation-target li {
        font-size: 10px; } }
    .motivation-target li .img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background: #eb1750;
      background: -moz-linear-gradient(top, #eb1750 0%, #d20881 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #eb1750), color-stop(100%, #d20881));
      background: -webkit-linear-gradient(top, #eb1750 0%, #d20881 100%);
      background: -o-linear-gradient(top, #eb1750 0%, #d20881 100%);
      background: -ms-linear-gradient(top, #eb1750 0%, #d20881 100%);
      background: linear-gradient(to bottom, #eb1750 0%, #d20881 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb1750', endColorstr='#d20881', GradientType=0 );
      border: 6px solid #fff;
      box-shadow: 0 4px 27px 0 rgba(0, 0, 0, 0.37);
      overflow: hidden;
      margin: 0 auto 10px; }
      @media (max-width: 767px) {
        .motivation-target li .img {
          width: 110px;
          height: 110px; } }
      .motivation-target li .img img {
        width: 100%; }
    .motivation-target li .title {
      font-size: 20px;
      display: block;
      font-weight: 700;
      display: block; }

.motivation-section {
  padding: 15px 0 15px 50px;
  border: 2px solid #c70f58;
  box-shadow: 0 4px 27px 0 rgba(0, 0, 0, 0.27);
  border-radius: 10px;
  margin-bottom: 5rem; }
  @media (max-width: 940px) {
    .motivation-section {
      padding-left: 40px;
      margin-bottom: 3.5rem; } }
  @media (max-width: 767px) {
    .motivation-section {
      border: 0;
      border-radius: 0;
      background: none;
      padding: 0;
      box-shadow: none; }
      .motivation-section .thead {
        display: none; } }
  .motivation-section .title {
    display: block;
    text-align: center;
    color: #000;
    font-size: 22px;
    text-transform: uppercase;
    margin: 0 auto 25px;
    position: relative;
    font-weight: 400;
    padding-left: 60px;
    display: table; }
    .motivation-section .title:after {
      content: "";
      width: 35px;
      height: 35px;
      background: url(../img/svg/box-p.svg) no-repeat;
      position: absolute;
      left: 0;
      top: -4px; }
    @media (max-width: 767px) {
      .motivation-section .title {
        font-size: 18px;
        padding: 0 50px; }
        .motivation-section .title:after {
          left: 8px;
          top: 3px; } }

.motivation-table .th {
  min-width: 110px;
  text-align: center;
  font-size: 16px;
  line-height: 45px;
  text-transform: uppercase; }
  .motivation-table .th.name {
    min-width: 230px; }
  .motivation-table .th.desk {
    flex: 1;
    padding-left: 55px;
    text-align: left; }

.motivation-table .td-wrap {
  width: 340px;
  margin: -1px 0;
  background: #ec008e;
  background: -moz-linear-gradient(left, #ec008e 0%, #eb1750 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #ec008e), color-stop(100%, #eb1750));
  background: -webkit-linear-gradient(left, #ec008e 0%, #eb1750 100%);
  background: -o-linear-gradient(left, #ec008e 0%, #eb1750 100%);
  background: -ms-linear-gradient(left, #ec008e 0%, #eb1750 100%);
  background: linear-gradient(to right, #ec008e 0%, #eb1750 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec008e', endColorstr='#eb1750', GradientType=1 );
  position: relative; }
  .motivation-table .td-wrap:after {
    content: "";
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
    border-left: 16px solid #ec1750; }
    @media (max-width: 767px) {
      .motivation-table .td-wrap:after {
        display: none; } }
  .motivation-table .td-wrap .name {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    max-width: 230px;
    padding: 0 23px 0 50px;
    height: 64px;
    text-align: center;
    justify-content: center;
    color: #fff; }
    @media (max-width: 767px) {
      .motivation-table .td-wrap .name {
        max-width: 70%;
        flex: 0 0 70%;
        padding-left: 10px; } }
    @media (max-width: 440px) {
      .motivation-table .td-wrap .name {
        max-width: 64%;
        flex: 0 0 64%; } }
    .motivation-table .td-wrap .name .name {
      padding: 0;
      font-weight: 300;
      font-size: 18px;
      line-height: 20px; }
      @media (max-width: 767px) {
        .motivation-table .td-wrap .name .name {
          font-size: 15px;
          line-height: 1.1;
          max-width: 100%; } }
  .motivation-table .td-wrap .balls {
    min-width: 110px;
    text-align: center;
    font-size: 30px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300; }
    @media (max-width: 940px) {
      .motivation-table .td-wrap .balls {
        font-size: 24px; } }
    @media (max-width: 767px) {
      .motivation-table .td-wrap .balls {
        font-size: 30px; } }
    @media (max-width: 440px) {
      .motivation-table .td-wrap .balls {
        max-width: 100%;
        min-width: 95px;
        font-size: 26px; } }
  .motivation-table .td-wrap .img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 5px solid #ec008c;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -30px;
    top: -4px;
    background: #fff; }
    @media (max-width: 767px) {
      .motivation-table .td-wrap .img {
        display: none; } }
    .motivation-table .td-wrap .img img {
      width: 100%;
      max-height: 38px;
      max-width: 42px; }
  .motivation-table .td-wrap .mob {
    display: none; }
  @media (max-width: 767px) {
    .motivation-table .td-wrap {
      width: 100%; }
      .motivation-table .td-wrap .mob {
        max-width: 40px;
        display: block;
        left: -6px;
        position: relative; } }

.motivation-table .tbody {
  border: 1px solid #bababa;
  border-width: 0 0 1px;
  margin-bottom: 15px; }
  @media (max-width: 767px) {
    .motivation-table .tbody {
      flex-wrap: wrap;
      border-radius: 10px;
      overflow: hidden;
      border: 0;
      background: #fff;
      box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.27); } }
  .motivation-table .tbody:nth-child(2) {
    border-width: 1px 0; }

.motivation-table .td.balls {
  position: relative; }
  .motivation-table .td.balls::before {
    content: "";
    width: 5px;
    height: 100%;
    background: url(../img/ellipse.png) repeat-y;
    position: absolute;
    left: 0;
    top: 0; }
    @media (max-width: 767px) {
      .motivation-table .td.balls::before {
        width: 1px; } }
  .motivation-table .td.balls::after {
    content: "";
    width: 19px;
    height: 19px;
    background: url(../img/svg/balls.svg) no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: -3px; }
    @media (max-width: 767px) {
      .motivation-table .td.balls::after {
        right: 7px; } }
    @media (max-width: 600px) {
      .motivation-table .td.balls::after {
        right: -2px; } }

.motivation-table .td.desk {
  flex: 1;
  padding-left: 55px;
  text-align: left;
  display: flex;
  padding-right: 30px;
  line-height: 19px;
  font-size: 18px;
  align-items: center; }
  @media (max-width: 1280px) {
    .motivation-table .td.desk {
      font-size: 16px;
      line-height: 1.1; } }
  @media (max-width: 940px) {
    .motivation-table .td.desk {
      padding-right: 10px;
      padding-left: 30px; } }
  @media (max-width: 767px) {
    .motivation-table .td.desk {
      padding: 10px;
      text-align: center; } }

.first-img {
  position: relative;
  z-index: 2;
  padding: 0 20px 15px 15px; }

.motivation-slider.num2 .slick-dots li {
  border-color: #fff; }
  .motivation-slider.num2 .slick-dots li.slick-active {
    background: #fff; }

.motivation-slider.num2 + .motivation-slider_nav .motivation-slider_prev {
  background: url(../img/svg/arrow-left_w.svg) no-repeat; }

.motivation-slider.num2 + .motivation-slider_nav .motivation-slider_next {
  background: url(../img/svg/arrow-right_w.svg) no-repeat; }

.slide2 {
  background: url(../img/slider-img7.jpg) no-repeat;
  background-size: cover;
  background-position: left top; }
  .slide2:after {
    display: none; }
  @media (max-width: 767px) {
    .slide2 {
      padding-right: 0 !important; } }
  @media (max-width: 670px) {
    .slide2 {
      padding-right: 0 !important;
      background: url(../img/slider7-mob.jpg) no-repeat;
      background-size: cover; } }
  .slide2 .info {
    padding-top: 210px;
    width: 370px;
    margin-left: auto; }
    @media (max-width: 1024px) {
      .slide2 .info {
        padding-top: 155px; } }
    @media (max-width: 767px) {
      .slide2 .info {
        width: 240px; } }
  .slide2 .info .btn {
    background: transparent; }

.main-footer {
  background: #f53a6c;
  background: -moz-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #f53a6c), color-stop(100%, #d006ae));
  background: -webkit-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
  background: -o-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
  background: -ms-linear-gradient(left, #f53a6c 0%, #d006ae 100%);
  background: linear-gradient(to right, #f53a6c 0%, #d006ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53a6c', endColorstr='#d006ae', GradientType=1 );
  padding: 15px 0;
  font-size: 1.33333rem;
  color: #fff; }
  .main-footer .row {
    align-items: center;
    position: relative; }
  .main-footer .copy {
    font-weight: 300; }
  .main-footer .logo {
    margin-right: 55px;
    display: inline-block; }
    .main-footer .logo:after {
      content: "";
      width: 37px;
      height: 87px;
      background: url(../img/shadow-l.png) no-repeat;
      position: absolute;
      top: -6px;
      left: 195px; }

@media (max-width: 1260px) {
  .main-footer .logo::after {
    display: none; }
  .main-footer .logo img {
    width: 140px; } }

@media (max-width: 959px) {
  .main-footer .logo img {
    width: 100px; } }
